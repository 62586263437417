import React, { useState } from "react";
import "./CreateSupervisor.scss";
import { Formik } from "formik";
import Spinner from "react-bootstrap/Spinner";
import axiosServices from "../../../../../services/axiosServices";
import { toast } from "react-toastify";
import ReuseSuccessModal2 from "../../../../../sharedComponent/modals/ReuseSuccessModal2";
import { useSelector } from "react-redux";

const CreateSupervisor = ({ toggleForward }) => {
  const Auth = useSelector((state) => state.Auth);
  const id = Auth?.isUser?.corporateId;
  const corporateName = Auth?.isUser?.corporateName;
  const corporateEmail = Auth?.isUser?.emailAddress;
  const [successModal, setSuccessModal] = useState(false);

  const text = "Registration Complete!";
  const text2 = "Vehicle(s) registration completed";

  return (
    <div className='cr-section'>
      {successModal && (
        <ReuseSuccessModal2
          setSuccessModal={setSuccessModal}
          text={text}
          text2={text2}
        />
      )}
      <div className='cr-section-header'>
        <h3 className='cr-section-header-title'>Create Supervisor</h3>
        <p className='cr-section-header-subtitle'>
          Fill in the registration data. It will take a couple of minutes.{" "}
        </p>
      </div>
      <div className='cr-section-body'>
        <div className='cr-section-body-content'>

          <Formik
            initialValues={{
              supervisorName: "",
              supervisorDailyLimit: "",
              supervisorMonthlyLimit: "",
              phoneNumber: "",
              emailAddress: "",
              password: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.supervisorName) {
                errors.supervisorName = "Required";
              }
              if (!values.phoneNumber) {
                errors.phoneNumber = "Required";
              }
              if (!values.emailAddress) {
                errors.emailAddress = "Email is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  values.emailAddress
                )
              ) {
                errors.emailAddress = "Invalid email address";
              }
              if (!values.password) {
                errors.password = "Password is required";
              } else if (values.password.length < 6) {
                errors.password = "Password must be at least 6 characters";
              }
              if (!values.supervisorDailyLimit) {
                errors.supervisorDailyLimit = "Required";
              }

              if (!values.supervisorMonthlyLimit) {
                errors.supervisorMonthlyLimit = "Required";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);

              const body = {
                clientId: "WEBAPP",
                corporateId: id,
                corporateName: corporateName,
                createdBy: corporateEmail,
                emailAddress: values.emailAddress,
                supervisorName: values.supervisorName,
                phoneNumber: values.phoneNumber,
                password: values.password,
                supervisorDailyLimit: values.supervisorDailyLimit,
                supervisorMonthlyLimit: values.supervisorMonthlyLimit,
              };

              try {
                const res = await axiosServices.post(`/createSupervisor`, body);
                if (res.responseCode === "00") {
                  setSubmitting(false);
                  toast.success(res.responseDescription);
                  setSuccessModal(true);
                  console.log("registration", res);
                } else {
                  toast.error(res.responseDescription);
                  setSubmitting(false);
                }
              } catch (error) {
                const errors = {};
                for (const key in error.response.data.errors) {
                  errors[key] = error.response.data.errors[key][0];
                }
                setSubmitting(false);
                console.log("error", error.response.data.errors);
                for (const key in errors) {
                  toast.error(errors[key]);
                }
              setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              dirty,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className='form_input_wrapper'>
                  <label>Name</label>
                  <input
                    type='text'
                    name='supervisorName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.supervisorName}
                    placeholder=''
                    className={
                      errors.supervisorName && touched.supervisorName
                        ? "input-error"
                        : null
                    }
                  />
                  {errors.supervisorName && touched.supervisorName && (
                    <span className='error'>{errors.supervisorName}</span>
                  )}
                </div>

                <div className='form_input_wrapper'>
                  <label>Email</label>
                  <input
                    type='text'
                    name='emailAddress'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.emailAddress}
                    placeholder=''
                    className={
                      errors.emailAddress && touched.emailAddress
                        ? "input-error"
                        : null
                    }
                  />
                  {errors.emailAddress && touched.emailAddress && (
                    <span className='error'>{errors.emailAddress}</span>
                  )}
                </div>

                <div className='form_input_wrapper'>
                  <label>Daily Limit</label>
                  <input
                    type='text'
                    name='supervisorDailyLimit'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.supervisorDailyLimit}
                    placeholder=''
                    className={
                      errors.supervisorDailyLimit && touched.supervisorDailyLimit
                        ? "input-error"
                        : null
                    }
                  />
                  {errors.supervisorDailyLimit && touched.supervisorDailyLimit && (
                    <span className='error'>{errors.supervisorDailyLimit}</span>
                  )}
                </div>

                <div className='form_input_wrapper'>
                  <label>Monthly Limit</label>
                  <input
                    type='text'
                    name='supervisorMonthlyLimit'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.supervisorMonthlyLimit}
                    placeholder=''
                    className={
                      errors.supervisorMonthlyLimit &&
                        touched.supervisorMonthlyLimit
                        ? "input-error"
                        : null
                    }
                  />
                  {errors.supervisorMonthlyLimit &&
                    touched.supervisorMonthlyLimit && (
                      <span className='error'>
                        {errors.supervisorMonthlyLimit}
                      </span>
                    )}
                </div>

                <div className='form_input_wrapper'>
                  <label>Phone Number</label>
                  <input
                    type='text'
                    name='phoneNumber'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                    placeholder=''
                    className={
                      errors.phoneNumber && touched.phoneNumber
                        ? "input-error"
                        : null
                    }
                  />
                  {errors.phoneNumber && touched.phoneNumber && (
                    <span className='error'>{errors.phoneNumber}</span>
                  )}
                </div>

                <div className='form_input_wrapper'>
                  <label>Password</label>
                  <input
                    type='password'
                    name='password'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    placeholder=''
                    className={
                      errors.password && touched.password ? "input-error" : null
                    }
                  />
                  {errors.password && touched.password && (
                    <span className='error'>{errors.password}</span>
                  )}
                </div>

                <div className='login-btn'>
                  <button type='submit' disabled={!dirty}>
                    {isSubmitting ? (
                      <Spinner
                        as='span'
                        animation='border'
                        // size='sm'
                        role='status'
                        aria-hidden='true'
                        variant='light'
                      />
                    ) : (
                      "Save & Continue"
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateSupervisor;
