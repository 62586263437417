import React from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import usePasswordToggle from "../tooglePassword/usePasswordToggle";
import "./Form.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  REGISTRATION_FAIL,
  REGISTRATION_SUCCESS,
} from "../../redux/actions/Auth";
import axiosServices from "../../services/axiosServices";

const RegisterFormIndividual = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const Auth = useSelector((state) => state.Auth);
  if (Auth?.isAuthenticated) {
    return <Navigate to='/dashboard/home' />;
  }

  return (
    <div className='form__wrapper'>
      <div className='form__title'>
        <h2>Register an Account!</h2>
        <p>
          For the purpose of industry regulation, your <br /> details are
          required.
        </p>
      </div>

      <div className='form__body'>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.firstName) {
              errors.firstName = "Required";
            }
            if (!values.lastName) {
              errors.lastName = "Required";
            }
            if (!values.email) {
              errors.email = "Company email is required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.password) {
              errors.password = "Password is required";
            } else if (values.password.length < 8) {
              errors.password = "Password must be at least 8 characters";
            }
            if (!values.confirmPassword) {
              errors.confirmPassword = "Confirm password is required";
            } else if (values.confirmPassword !== values.password) {
              errors.confirmPassword = "Password does not match";
            }
            if (!values.terms) {
              errors.terms = "You must agree to the terms and conditions";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            localStorage.setItem("contactEmail", values.email);

            const body = {
              first_name: values.firstName,
              last_name: values.lastName,
              email: values.email,
              password: values.password,
            };

            try {
              const res = await axiosServices.post(
                `/user/create-an-account/individual`,
                body
              );
              dispatch({
                type: REGISTRATION_SUCCESS,
                payload: res.data,
              });
              setSubmitting(false);
              navigate("/verify-account");
              // console.log("registration", res);
              // toast.error(res.responseDescription);
              localStorage.setItem("access_token", res.data.token)
              setSubmitting(false);
            } catch (error) {
              dispatch({
                type: REGISTRATION_FAIL,
              });
              const errors = {};
                for (const key in error.response.data.errors) {
                  errors[key] = error.response.data.errors[key][0];
                }
                setSubmitting(false);
                console.log("error", error.response.data.errors);
                for (const key in errors) {
                  toast.error(errors[key]);
                }
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className='form__groups'>
                <div className='form_input_wrapper'>
                  <label>First Name</label>
                  <input
                    type='text'
                    name='firstName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    placeholder='First Name'
                    className={
                      errors.firstName && touched.firstName
                        ? "input-error"
                        : null
                    }
                  />
                  {errors.firstName && touched.firstName && (
                    <span className='error'>{errors.firstName}</span>
                  )}
                </div>

                <div className='form_input_wrapper'>
                  <label>Last Name</label>
                  <input
                    type='text'
                    name='lastName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    placeholder='Last Name'
                    className={
                      errors.lastName && touched.lastName ? "input-error" : null
                    }
                  />
                  {errors.lastName && touched.lastName && (
                    <span className='error'>{errors.lastName}</span>
                  )}
                </div>
              </div>

              <div className='form_input_wrapper'>
                <label>Email Address</label>
                <input
                  type='text'
                  name='email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder='Email'
                  className={
                    errors.email && touched.email ? "input-error" : null
                  }
                />
                {errors.email && touched.email && (
                  <span className='error'>{errors.email}</span>
                )}
              </div>
              <div className='form_input_wrapper password-input'>
                <label>Password</label>
                <input
                  type={PasswordInputType}
                  name='password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  placeholder='Password'
                  className={
                    errors.password && touched.password ? "input-error" : null
                  }
                />
                <span className='password-toogle-icon'>{ToggleIcon}</span>
                {errors.password && touched.password && (
                  <span className='error'>{errors.password}</span>
                )}
              </div>
              <div className='form_input_wrapper password-input'>
                <label>Confirm Password</label>
                <input
                  type={PasswordInputType}
                  name='confirmPassword'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  placeholder='Password'
                  className={
                    errors.confirmPassword && touched.confirmPassword
                      ? "input-error"
                      : null
                  }
                />
                <span className='password-toogle-icon'>{ToggleIcon}</span>
                {errors.confirmPassword && touched.confirmPassword && (
                  <span className='error'>{errors.confirmPassword}</span>
                )}
              </div>

              {/* I agree to terms & conditions */}
              <div className='form_input_wrapper'>
                <label>
                  <input
                    type='checkbox'
                    name='terms'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.terms}
                    placeholder=''
                    style={{ marginRight: "10px" }}
                    className={
                      errors.terms && touched.terms ? "input-error" : null
                    }
                  />
                  I agree to terms & conditions
                </label>
                {errors.terms && touched.terms && (
                  <span className='error'>{errors.terms}</span>
                )}
              </div>
              <div className='login-btn'>
                <button type='submit' disabled={!dirty}>
                  {isSubmitting ? (
                    <Spinner
                      as='span'
                      animation='border'
                      // size='sm'
                      role='status'
                      aria-hidden='true'
                      variant='light'
                    />
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RegisterFormIndividual;
