import React, { useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import usePasswordToggle from "../tooglePassword/usePasswordToggle";
import {
  getUserDetail,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
} from "../../redux/actions/Auth";
import "./Form.scss";
import { useDispatch, useSelector } from "react-redux";
import axiosServices from "../../services/axiosServices";

const SetPasswordForm = () => {
  const dispatch = useDispatch();
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [isLoading, setIsLoading] = useState(false);
  const Auth = useSelector((state) => state.Auth);
  const navigate = useNavigate();

  // if (Auth?.isAuthenticated) {
  //   // dispatch(getUserDetail());
  //   return <Navigate to='/dashboard' />;
  // }

  return (
    <div className='form__wrapper'>
      <div className='form__title'>
        <h2>Set Password</h2>
      </div>

      <div className='form__body'>
        <Formik
          initialValues={{ old_password: "", new_password: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.old_password) {
              errors.old_password = "Password is required";
            } else if (values.old_password.length < 8) {
              errors.old_password = "Password must be at least 8 characters";
            }
            if (!values.new_password) {
              errors.new_password = "Password is required";
            } else if (values.new_password.length < 8) {
              errors.new_password = "Password must be at least 8 characters";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setIsLoading(true);
            const body = {
              old_password: values.old_password,
              new_password: values.new_password,
            };

            try {
              const res = await axiosServices.post(
                `/user/change-password`,
                body
              );

              // console.log("res.data", res.data);

              // dispatch({
              //   type: LOGIN_SUCCESS,
              //   payload: res.data,
              // });
              setIsLoading(false);
              toast.success("Set Password Successful");
              navigate("/set-pin");
            } catch (error) {
              // dispatch({
              //   type: LOGIN_FAIL,
              // });
              toast.error("Set Password Failed");
              setIsLoading(false);
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className='form_input_wrapper password-input'>
                <label>Old Password</label>
                <input
                  type={PasswordInputType}
                  name='old_password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.old_password}
                  placeholder='********'
                  className={
                    errors.old_password && touched.old_password
                      ? "input-error"
                      : null
                  }
                />
                <span className='password-toogle-icon'>{ToggleIcon}</span>
                {errors.old_password && touched.old_password && (
                  <span className='error'>{errors.old_password}</span>
                )}
              </div>
              <div className='form_input_wrapper password-input'>
                <label>New Password</label>
                <input
                  type={PasswordInputType}
                  name='new_password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.new_password}
                  placeholder='Password'
                  className={
                    errors.new_password && touched.new_password
                      ? "input-error"
                      : null
                  }
                />
                <span className='password-toogle-icon'>{ToggleIcon}</span>
                {errors.new_password && touched.new_password && (
                  <span className='error'>{errors.new_password}</span>
                )}
              </div>

              <div className='login-btn'>
                <button type='submit'>
                  {isLoading ? (
                    <Spinner
                      as='span'
                      animation='border'
                      // size='sm'
                      role='status'
                      aria-hidden='true'
                      variant='light'
                    />
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SetPasswordForm;
