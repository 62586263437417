import React, { useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import usePasswordToggle from "../tooglePassword/usePasswordToggle";
import {
  getUserDetail,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
} from "../../redux/actions/Auth";
import "./Form.scss";
import { useDispatch, useSelector } from "react-redux";
import axiosServices from "../../services/axiosServices";

const LoginForm = () => {
  const dispatch = useDispatch();
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [isLoading, setIsLoading] = useState(false);
  const Auth = useSelector((state) => state.Auth);
  const navigate = useNavigate();

  if (Auth?.isAuthenticated) {
    // dispatch(getUserDetail());
    return <Navigate to='/dashboard' />;
  }

  return (
    <div className='form__wrapper'>
      <div className='form__title'>
        <h2>Login</h2>
      </div>

      <div className='form__body'>
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Email is required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.password) {
              errors.password = "Password is required";
            } else if (values.password.length < 8) {
              errors.password = "Password must be at least 8 characters";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setIsLoading(true);
            const body = {
              email: values.email,
              password: values.password,
            };

            try {
              const res = await axiosServices.post(`/user/login`, body);

              // console.log("res.data", res.data);

              if (
                res?.data?.user?.persona === "SYSTEM_USER" ||
                res?.data?.user?.persona === "SUPER_ADMIN"
              ) {
                setIsLoading(false);
                toast.error(
                  "You are not authorized to login through this route"
                );
                return;
              }

              dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
              });

              // if (
              //   res?.data?.user?.persona === "SUPERVISOR" &&
              //   res?.data?.user?.registration_is_complete === false
              // ) {
              //   setIsLoading(false);
              //   // navigate("/set-password");
              //   navigate("/");
              //   toast.error(
              //     "You are not authorized to login through this route"
              //   );
              //   localStorage.clear();
              //   return;
              // }

              // if (
              //   res?.data?.user?.persona === "SUPERVISOR") {
              //   setIsLoading(false);
              //   // navigate("/set-password");
              //   navigate("/");
              //   toast.error(
              //     "You are not authorized to login through this route"
              //   );
              //   localStorage.clear();
              //   return;
              // }

              // dispatch({
              //   type: LOGIN_SUCCESS,
              //   payload: res.data,
              // });
              // dispatch(getUserDetail());
              setIsLoading(false);
              toast.success("Login Successful");
            } catch (error) {
              dispatch({
                type: LOGIN_FAIL,
              });
              // const errors = {};
              // for (const key in error.response.data.errors) {
              //   errors[key] = error.response.data.errors[key][0];
              // }
              // setSubmitting(false);
              // console.log("error", error.response.data.errors);
              // for (const key in errors) {
              //   toast.error(errors[key]);
              // }
              toast.error("Login Failed");
              setIsLoading(false);
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className='form_input_wrapper'>
                <label>Email Address</label>
                <input
                  type='text'
                  name='email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder='Email'
                  className={
                    errors.email && touched.email ? "input-error" : null
                  }
                />
                {errors.email && touched.email && (
                  <span className='error'>{errors.email}</span>
                )}
              </div>
              <div className='form_input_wrapper password-input'>
                <label>Password</label>
                <input
                  type={PasswordInputType}
                  name='password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  placeholder='Password'
                  className={
                    errors.password && touched.password ? "input-error" : null
                  }
                />
                <span className='password-toogle-icon'>{ToggleIcon}</span>
                {errors.password && touched.password && (
                  <span className='error'>{errors.password}</span>
                )}
                <div className='forgot__password__link'>
                  <Link to='/reset-password'>Forgot Password</Link>
                </div>
              </div>

              <div className='login-btn'>
                <button type='submit'>
                  {isLoading ? (
                    <Spinner
                      as='span'
                      animation='border'
                      role='status'
                      aria-hidden='true'
                      variant='light'
                    />
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LoginForm;
