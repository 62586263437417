import { Route, Routes } from "react-router";
import HomePage from "./pages/HomePage/HomePage";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OnBoard from "./pages/authPages/onBoard/OnBoard";
import Registration from "./pages/authPages/registration/Registration";
import Login from "./pages/authPages/login/Login";
import ResetPassord from "./pages/authPages/resetPassword/ResetPassword";
import ResetPasswordWithOtp from "./pages/authPages/resetPasswordWithOtp/ResetPasswordWithOtp";
import RegisterWithOtp from "./pages/authPages/registerWithOtp/RegisterWithOtp";
import loadable from "@loadable/component";
import CompleteYourRegistrationRetailer from "./pages/dashboard/retailer/completeYourRegistration/CompleteYourRegistrationRetailer";
import CompleteYourRegistration from "./pages/dashboard/coporate/completeYourRegistration/CompleteYourRegistration";
import CompleteYourRegistrationIndividual from "./pages/dashboard/individual/completeYourRegistration/CompleteYourRegistrationIndividual";
import AdminLogin from "./pages/authPages/admin/login/AdminLogin";
import ActivateAccount from "./pages/authPages/admin/activateAccount/ActivateAccount";
import CreatePin from "./pages/authPages/admin/createPin/CreatePin";
import ResponsiveDrawer from "./pages/adminDashboard/layout/drawer/ResponsiveDrawer";
import SetPassword from "./pages/authPages/setPassword/SetPassword";
import SetPin from "./pages/authPages/setPin/SetPin";
import PrivacyPolicy from "./pages/authPages/privacy-policy/PrivacyPolicy";
// import AdminDashboard from "./pages/adminDashboard";

const Loading = () => {
  return <div>Loading...</div>;
};

const Dashboard = loadable(() => import("./pages/dashboard"), {
  fallback: <Loading />,
});

const AdminDashboard = loadable(() => import("./pages/adminDashboard"), {
  fallback: <Loading />,
});

function App() {
  return (
    <div className='App'>
      <ToastContainer autoClose={3000} />
      <Routes>
        <Route exact path='/' element={<HomePage />} />
        <Route exact path='/onboard' element={<OnBoard />} />
        <Route exact path='/registration' element={<Registration />} />
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/reset-password' element={<ResetPassord />} />
        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route
          exact
          path='/reset-password-otp'
          element={<ResetPasswordWithOtp />}
        />
        <Route exact path='/verify-account' element={<RegisterWithOtp />} />
        <Route exact path='/set-password' element={<SetPassword />} />
        <Route exact path='/set-pin' element={<SetPin />} />
        <Route path='dashboard/*' element={<Dashboard />}></Route>
        <Route
          path='/complete-registration-corporate'
          element={<CompleteYourRegistration />}
        ></Route>
        <Route
          path='/complete-registration-retailer'
          element={<CompleteYourRegistrationRetailer />}
        ></Route>
        <Route
          path='/complete-registration-individual'
          element={<CompleteYourRegistrationIndividual />}
        ></Route>

        <Route path='/admin' element={<AdminLogin />}></Route>
        <Route path='/activate-account' element={<ActivateAccount />}></Route>
        <Route path='/create-pin' element={<CreatePin />}></Route>

        <Route path='admin-dashboard/*' element={<AdminDashboard />}></Route>
        <Route path='/drawer' element={<ResponsiveDrawer />}></Route>
        

      </Routes>
    </div>
  );
}

export default App;
