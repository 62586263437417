import React, { useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import usePasswordToggle from "../tooglePassword/usePasswordToggle";
import {
  getUserDetail,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
} from "../../redux/actions/Auth";
import "./Form.scss";
import { useDispatch, useSelector } from "react-redux";
import axiosServices from "../../services/axiosServices";

const SetPinForm = () => {
  const dispatch = useDispatch();
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [isLoading, setIsLoading] = useState(false);
  const Auth = useSelector((state) => state.Auth);
  const navigate = useNavigate();

  // if (Auth?.isAuthenticated) {
  //   // dispatch(getUserDetail());
  //   return <Navigate to='/dashboard' />;
  // }

  return (
    <div className='form__wrapper'>
      <div className='form__title'>
        <h2>Set Pin</h2>
      </div>

      <div className='form__body'>
        <Formik
          initialValues={{ transaction_pin: "", confirm_transaction_pin: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.transaction_pin) {
              errors.transaction_pin = "Pin is required";
            } else if (values.transaction_pin.length < 4) {
              errors.transaction_pin = "please enter 4 digit pin";
            }
            if (!values.confirm_transaction_pin) {
              errors.confirm_transaction_pin = "Pin is required";
            } 
            // else if (values.confirm_transaction !== values.transaction_pin) {
            //   errors.confirm_transaction_pin = "Pin does not match";
            // }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setIsLoading(true);
            const body = {
              transaction_pin: values.transaction_pin,
              confirm_transaction_pin: values.confirm_transaction_pin,
            };

            try {
              const res = await axiosServices.post(
                `/user/create-transaction-pin`,
                body
              );

              // console.log("res.data", res.data);
              localStorage.clear();

              // dispatch({
              //   type: LOGIN_SUCCESS,
              //   payload: res.data,
              // });
              setIsLoading(false);
              toast.success("Set Password Successful");
              navigate("/login");
            } catch (error) {
              // dispatch({
              //   type: LOGIN_FAIL,
              // });
              toast.error("Set Password Failed");
              setIsLoading(false);
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className='form_input_wrapper password-input'>
                <label>PIN</label>
                <input
                  type='text'
                  name='transaction_pin'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.transaction_pin}
                  placeholder=''
                  className={
                    errors.transaction_pin && touched.transaction_pin
                      ? "input-error"
                      : null
                  }
                />
                {/* <span className='password-toogle-icon'>{ToggleIcon}</span> */}
                {errors.transaction_pin && touched.transaction_pin && (
                  <span className='error'>{errors.transaction_pin}</span>
                )}
              </div>
              <div className='form_input_wrapper password-input'>
                <label>CONFIRM PIN</label>
                <input
                  type='text'
                  name='confirm_transaction_pin'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirm_transaction_pin}
                  placeholder=''
                  className={
                    errors.confirm_transaction_pin &&
                    touched.confirm_transaction_pin
                      ? "input-error"
                      : null
                  }
                />
                {/* <span className='password-toogle-icon'>{ToggleIcon}</span> */}
                {errors.confirm_transaction_pin &&
                  touched.confirm_transaction_pin && (
                    <span className='error'>
                      {errors.confirm_transaction_pin}
                    </span>
                  )}
              </div>

              <div className='login-btn'>
                <button type='submit'>
                  {isLoading ? (
                    <Spinner
                      as='span'
                      animation='border'
                      // size='sm'
                      role='status'
                      aria-hidden='true'
                      variant='light'
                    />
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SetPinForm;
