import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  // LOGOUT,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAIL,
  ACCOUNT_ACTIVATION_SUCCESS,
  ACCOUNT_ACTIVATION_FAILURE,
  USER_SUCCESS,
  USER_FAIL,
  LIST_GROUP_SUCCESS,
  LIST_GROUP_FAIL,
  GROUP_DETAILS_SUCCESS,
  GROUP_DETAILS_FAIL,
  LIST_SUPERVISOR_SUCCESS,
  LIST_SUPERVISOR_FAIL,
  LIST_VEHICLE_SUCCESS,
  LIST_VEHICLE_FAIL,
  LIST_ATTENDANT_SUCCESS,
  LIST_ATTENDANT_FAIL,
  LIST_INDIVIDUAL_VEHICLE_SUCCESS,
  LIST_INDIVIDUAL_VEHICLE_FAIL,
  WALLET_BALANCE_SUCCESS,
  WALLET_BALANCE_FAIL,
  USER_MANAGEMENT_SUCCESS,
  USER_MANAGEMENT_FAIL,
  DASHBOARD_DETAILS_SUCCESS,
  LIST_CORPORATE_PERSONA_SUCCESS,
  LIST_CORPORATE_PERSONA_FAIL,
  LIST_INDIVIDUAL_PERSONA_SUCCESS,
  LIST_INDIVIDUAL_PERSONA_FAIL,
  LIST_RETAILER_PERSONA_SUCCESS,
  LIST_RETAILER_PERSONA_FAIL,
  LIST_FACILITATOR_PERSONA_SUCCESS,
  LIST_FACILITATOR_PERSONA_FAIL,
  LOADING_PERSONA_DETAILS_SUCCESS,
  PERSONA_DETAILS_SUCCESS,
  PERSONA_DETAILS_FAIL,
  WALLET_HISTORY_SUCCESS,
  WALLET_HISTORY_FAIL,
  LOADING_WALLET_HISTORY_SUCCESS,
  WALLET_DEPOSIT_FAIL,
  WALLET_DEPOSIT_SUCCESS,
  LOADING_WALLET_DEPOSIT_SUCCESS,
  ATTENDANT_LOADING,
  FUEL_PURCHASE_SUCCESS,
  SYSTEM_USER_DETAILS_SUCCESS,
  SYSTEM_USER_DETAILS_FAIL,
  SYSTEM_USER_LOADING,
  SUPERVISOR_DASHBOARD_DETAILS_SUCCESS,
  SUPERVISOR_DASHBOARD_DETAILS_LOADING
} from "../actions/Auth";

const initialState = {
  access_email: localStorage.getItem("access_email"),
  isAuthenticated: JSON.parse(localStorage.getItem("user_details")),
  loading: false,
  data: null,
  updateAccount: [],
  loginSuccess: [],
  isError: false,
  basicAuth: [],
  isRegister: [],
  isUpdateAccount: [],
  group: [],
  supervisor: [],
  vehicle: [],
  individualVehicle: [],
  attendant: JSON.parse(localStorage.getItem("attendant")) || [],
  isUser: JSON.parse(localStorage.getItem("user_details")),
  isWalletBalance: [],
  groupDetails: [],
  userManagement: [],
  dashboard: [],
  corporatePersona: [],
  isLoadingPersona: false,
  totalCorporatePersonaInfo: [],
  personalDetails: [],
  isLoadingWalletHistory: false,
  walletHistory: [],
  isAttendantLoading: false,
  walletDeposit: [],
  isLoadingWalletDeposit: false,
  adminReports: [],
  sales: [],
  adminReportsLoading: false,
  systemUserDetails: [],
  systemUserLoading: false,
  supervisorDashboardDetails: [],
  supervisorDashboardLoading: false,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SUPERVISOR_DASHBOARD_DETAILS_LOADING:
      return {
        ...state,
        supervisorDashboardLoading: true,
      };
    case SUPERVISOR_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        supervisorDashboardDetails: payload,
        supervisorDashboardLoading: false,
      };
    case SYSTEM_USER_LOADING:
      return {
        ...state,
        systemUserLoading: payload,
      };
    case SYSTEM_USER_DETAILS_SUCCESS:
      return {
        ...state,
        systemUserDetails: payload,
        systemUserLoading: payload,
      };
    case SYSTEM_USER_DETAILS_FAIL:
      return {
        ...state,
        systemUserDetails: [],
        systemUserLoading: payload,
      };
    case FUEL_PURCHASE_SUCCESS:
      return {
        ...state,
        adminReports: payload,
        sales: payload,
      };
    case LOADING_WALLET_HISTORY_SUCCESS:
      return {
        ...state,
        isLoadingWalletHistory: payload,
      };
    case WALLET_HISTORY_SUCCESS:
      return {
        ...state,
        walletHistory: payload,
        isLoadingWalletHistory: false,
      };
    case WALLET_HISTORY_FAIL:
      return {
        ...state,
        isLoadingWalletHistory: false,
        walletHistory: [],
      };
      case WALLET_DEPOSIT_SUCCESS:
      return {
        ...state,
        walletDeposit: payload,
        isLoadingWalletDeposit: false,
      };
    case WALLET_DEPOSIT_FAIL:
      return {
        ...state,
        isLoadingWalletDeposit: false,
        walletDeposit: [],
      };
    case PERSONA_DETAILS_SUCCESS:
      return {
        ...state,
        personalDetails: payload,
        isLoadingPersona: false,
      };
    case LOADING_PERSONA_DETAILS_SUCCESS:
      return {
        ...state,
        isLoadingPersona: payload,
      };
    case LIST_CORPORATE_PERSONA_SUCCESS:
      return {
        ...state,
        corporatePersona: payload?.data,
        totalCorporatePersonaInfo: payload,
        isLoadingPersona: false,
      };
    case LIST_CORPORATE_PERSONA_FAIL:
      return {
        ...state,
        isLoadingPersona: false,
      };
    case DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        dashboard: payload,
      };
    case USER_MANAGEMENT_SUCCESS:
      return {
        ...state,
        userManagement: payload,
      };
    case USER_MANAGEMENT_FAIL:
      return {
        ...state,
        userManagement: [],
      };
    case WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        isWalletBalance: payload,
      };
    case WALLET_BALANCE_FAIL:
      return {
        ...state,
        isWalletBalance: [],
      };
    case ATTENDANT_LOADING:
      return {
        ...state,
        isAttendantLoading: payload,
      };
    case LIST_ATTENDANT_SUCCESS:
      return {
        ...state,
        attendant: payload,
        isAttendantLoading: false,
      };
    case LIST_ATTENDANT_FAIL:
      return {
        ...state,
        isAttendantLoading: false,
        // attendant: [],
      };
    case LIST_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicle: payload,
      };
    case LIST_VEHICLE_FAIL:
      return {
        ...state,
        vehicle: [],
      };
    case LIST_INDIVIDUAL_VEHICLE_SUCCESS:
      return {
        ...state,
        individualVehicle: payload,
      };
    case LIST_INDIVIDUAL_VEHICLE_FAIL:
      return {
        ...state,
        individualVehicle: [],
      };
    case LIST_SUPERVISOR_SUCCESS:
      return {
        ...state,
        supervisor: payload,
      };
    case LIST_SUPERVISOR_FAIL:
      return {
        ...state,
        supervisor: [],
      };
    case LIST_GROUP_SUCCESS:
      return {
        ...state,
        group: payload,
      };
    case LIST_GROUP_FAIL:
      return {
        ...state,
        group: [],
      };
    case GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        groupDetails: payload,
      };
    case GROUP_DETAILS_FAIL:
      return {
        ...state,
        groupDetails: [],
      };
    case USER_SUCCESS:
      localStorage.setItem("user_details", JSON.stringify(payload?.data));
      console.log("ghghj", payload);
      return {
        ...state,
        isUser: payload.data,
      };
    case REGISTRATION_SUCCESS:
      return {
        ...state,
        isRegister: payload,
      };
    case ACCOUNT_ACTIVATION_SUCCESS:
      return {
        ...state,
        isUpdateAccount: payload,
      };
    case USER_FAIL:
      return {
        ...state,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("access_token", payload?.token);
      localStorage.setItem("user_details", JSON.stringify(payload?.user));
      return {
        ...state,
        loginSuccess: payload,
        // isUser: payload,
        isAuthenticated: true,
      };
    case REGISTRATION_FAIL:
      return {
        ...state,
      };
    case ACCOUNT_ACTIVATION_FAILURE:
      return {
        ...state,
      };
    case LOGIN_FAIL:
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_details");
      return {
        ...state,
        access_token: null,
        isAuthenticated: false,
        loading: false,
      };
    // case LOGOUT:
    //   case BASIC_AUTH_FAILURE:
    //   return {
    //     ...state,
    //     isAuthenticated: false,
    //     loading: false,
    //     isError: true,
    //   };
    default:
      return state;
  }
}
