import React from "react";
import "./OnBoard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Link, Navigate, useNavigate } from "react-router-dom";
import logo from "./../../../assets/images/logo-1.svg";
import { useSelector } from "react-redux";

const OnBoard = () => {
  const Auth = useSelector((state) => state.Auth);
  const navigate = useNavigate();

  const handleClientCorporate = () => {
    localStorage.setItem("client", "corporate");
    navigate("/registration");
  };

  const handleClientIndividual = () => {
    localStorage.setItem("client", "individual");
    navigate("/registration");
  };

  const handleClientRetailer = (e) => {
    localStorage.setItem("client", "retailer");
    navigate("/registration");
  };

  if (Auth?.isAuthenticated) {
    return <Navigate to='/' />;
  }

  return (
    <div className='page-wrapper'>
      <div className='left-side'>
        <div className='left-side-content-header'>
          <h3>
            Already have an account? <Link to='/login'>Sign In</Link>
          </h3>
        </div>

        <div className='left-side-content-body'>
          <div className='left-side-content-body-text'>
            <FontAwesomeIcon icon={faQuoteLeft} className='faQuoteLeft' />
            <p className='text-content'>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
            </p>

            <p className='text-content-2'>
              Vincent Obi
              <FontAwesomeIcon icon={faCircleCheck} className='circle-check' />
            </p>

            <div className='last-icon-container'>
              <p></p>
              <p className='text-content-icon'>
                <svg
                  width='34'
                  height='33'
                  viewBox='0 0 34 33'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M21 0H33.5V33H0V20.5H21V0Z' fill='white' />
                </svg>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='right-side'>
        <div className='right-side-content-header'>
          <Link to='/'>
            <img src={logo} alt='logo' />
          </Link>
        </div>
        <div className='right-side-content-body'>
          <div className='right-side-content-body-text'>
            <h3>Join Us!</h3>
            <p>
              To begin this journey, tell us what type of <br />
              account you’d be opening.
            </p>
          </div>

          <div className='right-side-content-body-button'>
            <div
              className='onboard-cta-btn'
              onClick={() => handleClientIndividual()}
            >
              <div className='icon-container'>
                <svg
                  width='52'
                  height='53'
                  viewBox='0 0 52 53'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M2.09543 18.7329L26 1.36525L49.9046 18.7329L40.7738 46.8344H11.2262L2.09543 18.7329Z'
                    stroke='#575F6E'
                    strokeWidth='1.4'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M22.6667 24.0001C22.6667 23.116 23.0179 22.2682 23.643 21.6431C24.2681 21.018 25.1159 20.6668 26 20.6668C26.8841 20.6668 27.7319 21.018 28.357 21.6431C28.9821 22.2682 29.3333 23.116 29.3333 24.0001C29.3333 24.8842 28.9821 25.732 28.357 26.3571C27.7319 26.9822 26.8841 27.3334 26 27.3334C25.1159 27.3334 24.2681 26.9822 23.643 26.3571C23.0179 25.732 22.6667 24.8842 22.6667 24.0001V24.0001ZM29.1867 27.8526C29.9796 27.1966 30.5508 26.312 30.8224 25.3194C31.0939 24.3268 31.0526 23.2746 30.704 22.3063C30.3554 21.3381 29.7166 20.501 28.8746 19.9093C28.0327 19.3175 27.0287 19 25.9996 19C24.9705 19 23.9665 19.3175 23.1245 19.9093C22.2826 20.501 21.6438 21.3381 21.2952 22.3063C20.9466 23.2746 20.9053 24.3268 21.1768 25.3194C21.4484 26.312 22.0196 27.1966 22.8125 27.8526C20.155 28.7884 18.5 30.8726 18.5 33.1668C18.5 33.3878 18.5878 33.5997 18.7441 33.756C18.9004 33.9123 19.1123 34.0001 19.3333 34.0001C19.5543 34.0001 19.7663 33.9123 19.9226 33.756C20.0789 33.5997 20.1667 33.3878 20.1667 33.1668C20.1667 31.2751 22.1292 29.0001 26 29.0001C29.8708 29.0001 31.8333 31.2751 31.8333 33.1668C31.8333 33.3878 31.9211 33.5997 32.0774 33.756C32.2337 33.9123 32.4457 34.0001 32.6667 34.0001C32.8877 34.0001 33.0996 33.9123 33.2559 33.756C33.4122 33.5997 33.5 33.3878 33.5 33.1668C33.5 30.8726 31.8467 28.7884 29.1867 27.8526Z'
                    fill='#575F6E'
                  />
                </svg>
              </div>
              <div className='text-container'>
                <h3>Individual</h3>
                <p>Personal account to manage all your activities.</p>
              </div>
            </div>

            <div
              className='onboard-cta-btn'
              onClick={() => handleClientCorporate()}
            >
              <div className='icon-container'>
                <svg
                  width='53'
                  height='53'
                  viewBox='0 0 53 53'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M26.5 0.5L51.2275 18.4656L41.7824 47.5344H11.2176L1.77253 18.4656L26.5 0.5Z'
                    fill='#0D30B9'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M19.3335 22.6667C18.8733 22.6667 18.5002 23.0398 18.5002 23.5001V31.8334C18.5002 32.2937 18.8733 32.6667 19.3335 32.6667H32.6668C33.1271 32.6667 33.5002 32.2937 33.5002 31.8334V23.5001C33.5002 23.0398 33.1271 22.6667 32.6668 22.6667H19.3335ZM16.8335 23.5001C16.8335 22.1194 17.9528 21.0001 19.3335 21.0001H32.6668C34.0475 21.0001 35.1668 22.1194 35.1668 23.5001V31.8334C35.1668 33.2141 34.0475 34.3334 32.6668 34.3334H19.3335C17.9528 34.3334 16.8335 33.2141 16.8335 31.8334V23.5001Z'
                    fill='white'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M22.5657 18.399C23.0346 17.9301 23.6705 17.6667 24.3335 17.6667H27.6668C28.3299 17.6667 28.9658 17.9301 29.4346 18.399C29.9034 18.8678 30.1668 19.5037 30.1668 20.1667V33.5001C30.1668 33.9603 29.7937 34.3334 29.3335 34.3334C28.8733 34.3334 28.5002 33.9603 28.5002 33.5001V20.1667C28.5002 19.9457 28.4124 19.7338 28.2561 19.5775C28.0998 19.4212 27.8878 19.3334 27.6668 19.3334H24.3335C24.1125 19.3334 23.9005 19.4212 23.7442 19.5775C23.588 19.7338 23.5002 19.9457 23.5002 20.1667V33.5001C23.5002 33.9603 23.1271 34.3334 22.6668 34.3334C22.2066 34.3334 21.8335 33.9603 21.8335 33.5001V20.1667C21.8335 19.5037 22.0969 18.8678 22.5657 18.399Z'
                    fill='white'
                  />
                </svg>
              </div>
              <div className='text-container'>
                <h3>Corporate Organisation</h3>
                <p>Own or belong to a company this is for you.</p>
              </div>
            </div>

            <div
              className='onboard-cta-btn'
              onClick={() => handleClientRetailer()}
            >
              <div className='icon-container'>
                <svg
                  width='52'
                  height='53'
                  viewBox='0 0 52 53'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M2.09543 18.7329L26 1.36525L49.9046 18.7329L40.7738 46.8344H11.2262L2.09543 18.7329Z'
                    stroke='#575F6E'
                    strokeWidth='1.4'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M34.2998 17.5748L36.3498 19.4498C36.4459 19.5377 36.5 19.6567 36.5 19.7813V32.9062C36.5 33.6817 35.8103 34.3125 34.9625 34.3125C34.1147 34.3125 33.425 33.6817 33.425 32.9062V31.9688C33.425 31.7101 33.1953 31.5 32.9125 31.5H32.4V35.25C33.5306 35.25 34.45 36.0909 34.45 37.125V38.5312C34.45 38.7903 34.2208 39 33.9375 39H16.5125C16.2292 39 16 38.7903 16 38.5312V37.125C16 36.0909 16.9194 35.25 18.05 35.25V18.375C18.05 17.3409 18.9694 16.5 20.1 16.5H30.35C31.4806 16.5 32.4 17.3409 32.4 18.375V30.5625H32.9124C33.7603 30.5625 34.4499 31.1933 34.4499 31.9687V32.9062C34.4499 33.1649 34.6797 33.375 34.9625 33.375C35.2453 33.375 35.475 33.1648 35.475 32.9062V24.9375C34.3444 24.9375 33.425 24.0966 33.425 23.0625V21.6562C33.425 21.5317 33.479 21.4127 33.5751 21.3248L35.2628 19.7812L33.5751 18.2376C33.3749 18.0546 33.3749 17.7579 33.5751 17.5748C33.7753 17.3917 34.0996 17.3917 34.2998 17.5748ZM34.4502 23.0626C34.4502 23.5794 34.9101 24.0001 35.4752 24.0001V20.9129L34.4502 21.8504V23.0626Z'
                    fill='#575F6E'
                  />
                  <path
                    d='M29.8376 18.375H20.6126C20.3293 18.375 20.1001 18.5847 20.1001 18.8438V24.4775C20.1001 24.7366 20.3293 24.9462 20.6126 24.9462H29.8376C30.1209 24.9462 30.3501 24.7366 30.3501 24.4775V18.8437C30.35 18.5847 30.1209 18.375 29.8376 18.375Z'
                    fill='white'
                  />
                </svg>
              </div>
              <div className='text-container'>
                <h3>Fuel Retailer</h3>
                <p>Own or belong to a Fuel Station, this is for you.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnBoard;
