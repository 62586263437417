import React, { useState } from "react";
import "./SuccessModal.scss";
import Modal from "react-modal";
import { Link, useNavigate } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "100%",
    width: "458px",
  },
};

const ReuseSuccessModal = ({ setSuccessModal, text, text2, toggleForward }) => {
  // let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  //   function openModal() {
  //     setIsOpen(true);
  //   }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //   subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(!modalIsOpen);
    setSuccessModal(false);
    navigate("/dashboard");
  }

  function nextPage() {
    toggleForward();
    setIsOpen(!modalIsOpen);
    setSuccessModal(false);
  }

  return (
    <div>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={!modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={nextPage}
        style={customStyles}
        contentLabel='Example Modal'
        ariaHideApp={false}
      >
        {/* <button onClick={nextPage}>close</button> */}
        <svg onClick={closeModal} style={{ cursor: "pointer" }} width="67" height="14" viewBox="0 0 67 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9999 1L1.33325 13" stroke="black" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M1.33325 1L13.3333 13" stroke="black" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M37.7594 5.5C37.3815 3.41193 35.7111 2.17898 33.6628 2.17898C31.0577 2.17898 29.1685 4.1875 29.1685 7.40909C29.1685 10.6307 31.0577 12.6392 33.6628 12.6392C35.7111 12.6392 37.3815 11.4062 37.7594 9.31818H36.5264C36.2281 10.7301 35.0151 11.4858 33.6628 11.4858C31.8134 11.4858 30.3617 10.054 30.3617 7.40909C30.3617 4.7642 31.8134 3.33239 33.6628 3.33239C35.0151 3.33239 36.2281 4.08807 36.5264 5.5H37.7594ZM41.046 2.31818H39.8727V12.5H41.046V2.31818ZM46.5772 12.6591C48.6454 12.6591 50.0374 11.0881 50.0374 8.72159C50.0374 6.33523 48.6454 4.7642 46.5772 4.7642C44.509 4.7642 43.117 6.33523 43.117 8.72159C43.117 11.0881 44.509 12.6591 46.5772 12.6591ZM46.5772 11.6051C45.0062 11.6051 44.2903 10.2528 44.2903 8.72159C44.2903 7.19034 45.0062 5.81818 46.5772 5.81818C48.1482 5.81818 48.8641 7.19034 48.8641 8.72159C48.8641 10.2528 48.1482 11.6051 46.5772 11.6051ZM57.5175 6.57386C57.1496 5.49006 56.3244 4.7642 54.813 4.7642C53.2022 4.7642 52.009 5.67898 52.009 6.97159C52.009 8.02557 52.6354 8.73153 54.0374 9.05966L55.3101 9.35795C56.0807 9.53693 56.4437 9.90483 56.4437 10.4318C56.4437 11.0881 55.7476 11.625 54.6539 11.625C53.6944 11.625 53.0928 11.2124 52.884 10.392L51.7704 10.6705C52.0438 11.968 53.1127 12.6591 54.6738 12.6591C56.4486 12.6591 57.6567 11.6896 57.6567 10.3722C57.6567 9.30824 56.9905 8.63707 55.6283 8.30398L54.4948 8.02557C53.59 7.80185 53.1823 7.49858 53.1823 6.91193C53.1823 6.25568 53.8783 5.77841 54.813 5.77841C55.8371 5.77841 56.2597 6.34517 56.4636 6.87216L57.5175 6.57386ZM62.9046 12.6591C64.4557 12.6591 65.5893 11.8835 65.9472 10.7301L64.8137 10.4119C64.5154 11.2074 63.8243 11.6051 62.9046 11.6051C61.5275 11.6051 60.5779 10.7152 60.5232 9.07955H66.0665V8.58239C66.0665 5.73864 64.3762 4.7642 62.7853 4.7642C60.7171 4.7642 59.3449 6.39489 59.3449 8.74148C59.3449 11.0881 60.6972 12.6591 62.9046 12.6591ZM60.5232 8.06534C60.6028 6.87713 61.443 5.81818 62.7853 5.81818C64.058 5.81818 64.8734 6.77273 64.8734 8.06534H60.5232Z" fill="#2B1C1C" />
        </svg>
        <div className='modal__container'>
          <div className='modal__header'>
            <svg
              width='49'
              height='48'
              viewBox='0 0 49 48'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g clipPath='url(#clip0_2244_1390)'>
                <path
                  d='M16.0013 9.98391C15.852 10.1332 15.7387 10.3132 15.644 10.5106L15.6333 10.4999L0.678668 44.1879L0.693335 44.2026C0.416002 44.7399 0.880002 45.8332 1.83067 46.7852C2.78134 47.7359 3.87467 48.1999 4.412 47.9226L4.42534 47.9359L38.1133 32.9799L38.1027 32.9679C38.2987 32.8746 38.4787 32.7612 38.6293 32.6092C40.712 30.5266 37.3347 23.7732 31.088 17.5252C24.8387 11.2772 18.0853 7.90124 16.0013 9.98391V9.98391Z'
                  fill='#DD2E44'
                />
                <path
                  d='M17.8333 16L1.05467 43.3413L0.678668 44.188L0.693335 44.2027C0.416002 44.74 0.880002 45.8333 1.83067 46.7853C2.14 47.0947 2.46133 47.3293 2.776 47.528L23.1667 22.6667L17.8333 16Z'
                  fill='#EA596E'
                />
                <path
                  d='M31.1828 17.4214C37.4095 23.6507 40.8668 30.2907 38.9015 32.2534C36.9375 34.2187 30.2975 30.7627 24.0668 24.5361C17.8388 18.3067 14.3828 11.6641 16.3468 9.70006C18.3121 7.73606 24.9521 11.1921 31.1828 17.4214V17.4214Z'
                  fill='#A0041E'
                />
                <path
                  d='M25.2867 18.1453C25.0214 18.36 24.6747 18.472 24.308 18.432C23.1507 18.3067 22.1774 17.904 21.496 17.268C20.7747 16.5947 20.4187 15.6907 20.516 14.7853C20.6867 13.196 22.2814 11.7373 25 12.0307C26.0574 12.144 26.5294 11.804 26.5454 11.6413C26.564 11.48 26.176 11.0467 25.1187 10.932C23.9614 10.8067 22.988 10.404 22.3054 9.76799C21.584 9.09465 21.2267 8.19065 21.3254 7.28532C21.4987 5.69599 23.092 4.23732 25.808 4.53199C26.5787 4.61465 26.9854 4.45599 27.1574 4.35332C27.2947 4.26932 27.3494 4.18932 27.3547 4.14265C27.3707 3.98132 26.988 3.54799 25.928 3.43332C25.196 3.35332 24.6654 2.69732 24.7467 1.96399C24.8254 1.23199 25.48 0.702655 26.2147 0.782655C28.9307 1.07465 30.1787 2.83865 30.0067 4.42932C29.8334 6.02132 28.24 7.47732 25.5214 7.18532C24.7507 7.10132 24.348 7.26132 24.1747 7.36399C24.0374 7.44665 23.9814 7.52799 23.976 7.57332C23.9587 7.73599 24.344 8.16799 25.404 8.28265C28.12 8.57599 29.368 10.3387 29.196 11.9293C29.024 13.5187 27.4307 14.9773 24.7134 14.6827C23.9427 14.6 23.5374 14.76 23.364 14.8613C23.2254 14.9467 23.172 15.0267 23.1667 15.072C23.1494 15.2333 23.5347 15.6667 24.5934 15.7813C25.324 15.8613 25.856 16.5187 25.7747 17.2507C25.7374 17.616 25.552 17.932 25.2867 18.1453V18.1453Z'
                  fill='#AA8DD8'
                />
                <path
                  d='M41.3815 30.476C44.0121 29.7333 45.8268 30.9066 46.2588 32.4466C46.6908 33.9853 45.7548 35.9333 43.1255 36.6733C42.0988 36.9613 41.7908 37.452 41.8321 37.608C41.8775 37.7653 42.3988 38.024 43.4228 37.7346C46.0521 36.9946 47.8668 38.168 48.2988 39.7066C48.7335 41.2466 47.7948 43.192 45.1641 43.9333C44.1388 44.2213 43.8295 44.7133 43.8748 44.8693C43.9188 45.0253 44.4388 45.284 45.4641 44.996C46.1708 44.7973 46.9095 45.2093 47.1081 45.9173C47.3055 46.6266 46.8935 47.3626 46.1841 47.5626C43.5561 48.3026 41.7401 47.132 41.3055 45.5906C40.8735 44.052 41.8108 42.1066 44.4428 41.3653C45.4695 41.076 45.7775 40.5866 45.7321 40.4293C45.6895 40.2733 45.1695 40.0133 44.1455 40.3013C41.5135 41.0426 39.7001 39.872 39.2668 38.3293C38.8335 36.7906 39.7708 34.8453 42.4015 34.1026C43.4255 33.816 43.7335 33.3226 43.6908 33.168C43.6455 33.0106 43.1268 32.752 42.1015 33.04C41.3921 33.24 40.6575 32.8266 40.4575 32.1186C40.2588 31.412 40.6721 30.676 41.3815 30.476V30.476Z'
                  fill='#77B255'
                />
                <path
                  d='M31.1679 26.88C30.7759 26.88 30.3893 26.708 30.1253 26.38C29.6653 25.804 29.7599 24.9653 30.3333 24.5053C30.6239 24.272 37.5573 18.8267 47.3559 20.228C48.0853 20.332 48.5919 21.0067 48.4879 21.736C48.3839 22.464 47.7146 22.976 46.9786 22.8667C38.3213 21.6373 32.0626 26.5387 32.0013 26.588C31.7533 26.7853 31.4599 26.88 31.1679 26.88V26.88Z'
                  fill='#AA8DD8'
                />
                <path
                  d='M8.17188 21.3335C8.04521 21.3335 7.91588 21.3148 7.78788 21.2775C7.08255 21.0655 6.68255 20.3228 6.89455 19.6175C8.40521 14.5868 9.77455 6.55879 8.09188 4.46545C7.90388 4.22812 7.61988 3.99479 6.96921 4.04412C5.71855 4.14012 5.83721 6.77879 5.83855 6.80545C5.89455 7.54012 5.34255 8.18012 4.60921 8.23479C3.86388 8.28012 3.23455 7.73879 3.17988 7.00412C3.04255 5.16545 3.61455 1.62412 6.76921 1.38545C8.17721 1.27879 9.34655 1.76812 10.1719 2.79479C13.3332 6.72945 10.1239 18.1361 9.44921 20.3841C9.27588 20.9615 8.74521 21.3335 8.17188 21.3335Z'
                  fill='#77B255'
                />
                <path
                  d='M34.5 14.6667C35.6046 14.6667 36.5 13.7713 36.5 12.6667C36.5 11.5622 35.6046 10.6667 34.5 10.6667C33.3954 10.6667 32.5 11.5622 32.5 12.6667C32.5 13.7713 33.3954 14.6667 34.5 14.6667Z'
                  fill='#5C913B'
                />
                <path
                  d='M3.16667 26.6666C4.63943 26.6666 5.83333 25.4727 5.83333 23.9999C5.83333 22.5272 4.63943 21.3333 3.16667 21.3333C1.69391 21.3333 0.5 22.5272 0.5 23.9999C0.5 25.4727 1.69391 26.6666 3.16667 26.6666Z'
                  fill='#9266CC'
                />
                <path
                  d='M43.8333 28C44.9378 28 45.8333 27.1046 45.8333 26C45.8333 24.8954 44.9378 24 43.8333 24C42.7287 24 41.8333 24.8954 41.8333 26C41.8333 27.1046 42.7287 28 43.8333 28Z'
                  fill='#5C913B'
                />
                <path
                  d='M31.8333 44C32.9378 44 33.8333 43.1046 33.8333 42C33.8333 40.8954 32.9378 40 31.8333 40C30.7287 40 29.8333 40.8954 29.8333 42C29.8333 43.1046 30.7287 44 31.8333 44Z'
                  fill='#5C913B'
                />
                <path
                  d='M37.8334 8.00008C39.3062 8.00008 40.5001 6.80617 40.5001 5.33341C40.5001 3.86066 39.3062 2.66675 37.8334 2.66675C36.3607 2.66675 35.1667 3.86066 35.1667 5.33341C35.1667 6.80617 36.3607 8.00008 37.8334 8.00008Z'
                  fill='#FFCC4D'
                />
                <path
                  d='M43.8333 13.3333C44.9378 13.3333 45.8333 12.4378 45.8333 11.3333C45.8333 10.2287 44.9378 9.33325 43.8333 9.33325C42.7287 9.33325 41.8333 10.2287 41.8333 11.3333C41.8333 12.4378 42.7287 13.3333 43.8333 13.3333Z'
                  fill='#FFCC4D'
                />
                <path
                  d='M39.8333 18.6667C40.9378 18.6667 41.8332 17.7713 41.8332 16.6667C41.8332 15.5622 40.9378 14.6667 39.8333 14.6667C38.7287 14.6667 37.8333 15.5622 37.8333 16.6667C37.8333 17.7713 38.7287 18.6667 39.8333 18.6667Z'
                  fill='#FFCC4D'
                />
                <path
                  d='M10.5 33.3334C11.6046 33.3334 12.5 32.4379 12.5 31.3334C12.5 30.2288 11.6046 29.3334 10.5 29.3334C9.39543 29.3334 8.5 30.2288 8.5 31.3334C8.5 32.4379 9.39543 33.3334 10.5 33.3334Z'
                  fill='#FFCC4D'
                />
              </g>
              <defs>
                <clipPath id='clip0_2244_1390'>
                  <rect
                    width='48'
                    height='48'
                    fill='white'
                    transform='translate(0.5)'
                  />
                </clipPath>
              </defs>
            </svg>

            <h2>{text}</h2>
            <p>{text2}</p>
          </div>
          <div className='modal-cta-btn2'>
            <button className="skip_cta" onClick={closeModal}>
              <Link to='/dashboard'>Skip</Link>
            </button>
            <button className="cont_cta" onClick={nextPage}>Continue</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReuseSuccessModal;
