import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./NavBar.scss";
import logo from "../../assets/images/logo-1.svg";

const NavBar = () => {
  const Auth = useSelector((state) => state.Auth);
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  const closeMobileMenu = () => setClick(false);

  return (
    <div className='container navbar-wrapper'>
      <div className='navbar-container container'>
        <ul>
          <li>
            <img src={logo} alt='logo' />
          </li>
        </ul>
        <ul className='menu-bar'>
          <li onClick={() => handleClick()}>
            <i className='fas fa-bars'></i>
          </li>
        </ul>
        <ul className='nav_links'>
          <li>Home</li>
          <li>About Us </li>
          <li>How It Works</li>
          <li>Screenshots</li>
          <li>Testimonials </li>
          {Auth?.isAuthenticated ? (
            // <Link to='#/'>
            <li
              className='special'
              onClick={() => {
                localStorage.clear();
                window.location.href = "/";
              }}
            >
              logout
            </li>
          ) : (
            // </Link>
            <Link to='/login'>
              <li className='special'>login</li>
            </Link>
          )}
        </ul>
      </div>
      {click && (
        <div className={click ? "active" : "nav_menu"}>
          <div className='close' onClick={() => closeMobileMenu()}>
            <i className='fas fa-times'></i>
          </div>
          <ul className='mobile-menu-links '>
            <li onClick={() => closeMobileMenu()}>
              <Link to='/'>Home</Link>
            </li>
            <li onClick={() => closeMobileMenu()}>
              <Link to='#'>About Us </Link>
            </li>
            <li onClick={() => closeMobileMenu()}>
              <Link to='#'>How It Works</Link>
            </li>
            <li onClick={() => closeMobileMenu()}>
              <Link to='#'>Screenshots</Link>
            </li>
            <li onClick={() => closeMobileMenu()}>
              <Link to='#'>Testimonials </Link>
            </li>
            {Auth?.isAuthenticated ? (
              <li
                className='special'
                onClick={() => {
                  localStorage.clear();
                  window.location.href = "/";
                  setClick(!click);
                }}
              >
                logout
              </li>
            ) : (
              <li className='special'>
                <Link to='/login'>login</Link>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default NavBar;
