import axios from "axios";
import axiosServices from "../../services/axiosServices";

export const ACCOUNT_ACTIVATION_SUCCESS = "ACCOUNT_ACTIVATION_SUCCESS";
export const ACCOUNT_ACTIVATION_FAILURE = "ACCOUNT_ACTIVATION_FAILURE";

export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAIL = "USER_FAIL";

export const SUPERVISOR_DASHBOARD_DETAILS_SUCCESS =
  "SUPERVISOR_DASHBOARD_DETAILS_SUCCESS";
export const SUPERVISOR_DASHBOARD_DETAILS_FAIL =
  "SUPERVISOR_DASHBOARD_DETAILS_FAIL";

export const SUPERVISOR_DASHBOARD_DETAILS_LOADING =
  "SUPERVISOR_DASHBOARD_DETAILS_LOADING";

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAIL = "REGISTRATION_FAIL";

export const LIST_GROUP_SUCCESS = "LIST_GROUP_SUCCESS";
export const LIST_GROUP_FAIL = "LIST_GROUP_FAIL";

export const GROUP_DETAILS_SUCCESS = "GROUP_DETAILS_SUCCESS";
export const GROUP_DETAILS_FAIL = "GROUP_DETAILS_FAIL";

export const LIST_SUPERVISOR_SUCCESS = "LIST_SUPERVISOR_SUCCESS";
export const LIST_SUPERVISOR_FAIL = "LIST_SUPERVISOR_FAIL";

export const LIST_VEHICLE_SUCCESS = "LIST_VEHICLE_SUCCESS";
export const LIST_VEHICLE_FAIL = "LIST_VEHICLE_FAIL";

export const LIST_INDIVIDUAL_VEHICLE_SUCCESS =
  "LIST_INDIVIDUAL_VEHICLE_SUCCESS";
export const LIST_INDIVIDUAL_VEHICLE_FAIL = "LIST_INDIVIDUAL_VEHICLE_FAIL";

export const LIST_ATTENDANT_SUCCESS = "LIST_ATTENDANT_SUCCESS";
export const LIST_ATTENDANT_FAIL = "LIST_ATTENDANT_FAIL";

export const ASSIGN_SUPERVISOR_TO_GROUP_SUCCESS =
  "ASSIGN_SUPERVISOR_TO_GROUP_SUCCESS";
export const ASSIGN_SUPERVISOR_TO_GROUP_FAIL =
  "ASSIGN_SUPERVISOR_TO_GROUP_FAIL";

export const WALLET_BALANCE_SUCCESS = "WALLET_BALANCE_SUCCESS";
export const WALLET_BALANCE_FAIL = "WALLET_BALANCE_FAIL";

export const WALLET_HISTORY_SUCCESS = "WALLET_HISTORY_SUCCESS";
export const WALLET_HISTORY_FAIL = "WALLET_HISTORY_FAIL";

export const WALLET_DEPOSIT_SUCCESS = "WALLET_DEPOSIT_SUCCESS";
export const WALLET_DEPOSIT_FAIL = "WALLET_DEPOSIT_FAIL";
export const WALLET_DEPOSIT_LOADING = "WALLET_DEPOSIT_LOADING";

// export const LOGOUT = "LOGOUT";

export const ATTENDANT_LOADING = "ATTENDANT_LOADING";

export const GET_INDIVIDUAL_DETAILS_FOR_ADMIN_SUCCESS =
  "GET_INDIVIDUAL_DETAILS_FOR_ADMIN_SUCCESS";
export const GET_INDIVIDUAL_DETAILS_FOR_ADMIN_FAIL =
  "GET_INDIVIDUAL_DETAILS_FOR_ADMIN_FAIL";

export const GET_CORPORATE_DETAILS_FOR_ADMIN_SUCCESS =
  "GET_CORPORATE_DETAILS_FOR_ADMIN_SUCCESS";
export const GET_CORPORATE_DETAILS_FOR_ADMIN_FAIL =
  "GET_CORPORATE_DETAILS_FOR_ADMIN_FAIL";

export const GET_RETAILER_DETAILS_FOR_ADMIN_SUCCESS =
  "GET_RETAILER_DETAILS_FOR_ADMIN_SUCCESS";
export const GET_RETAILER_DETAILS_FOR_ADMIN_FAIL =
  "GET_RETAILER_DETAILS_FOR_ADMIN_FAIL";

export const USER_MANAGEMENT_SUCCESS = "USER_MANAGEMENT_SUCCESS";
export const USER_MANAGEMENT_FAIL = "USER_MANAGEMENT_FAIL";

export const DASHBOARD_DETAILS_SUCCESS = "DASHBOARD_DETAILS_SUCCESS";
export const DASHBOARD_DETAILS_FAIL = "DASHBOARD_DETAILS_FAIL";

export const LIST_CORPORATE_PERSONA_SUCCESS = "LIST_CORPORATE_PERSONA_SUCCESS";
export const LIST_CORPORATE_PERSONA_FAIL = "LIST_CORPORATE_PERSONA_FAIL";

export const LIST_RETAILER_PERSONA_SUCCESS = "LIST_RETAILER_PERSONA_SUCCESS";
export const LIST_RETAILER_PERSONA_FAIL = "LIST_RETAILER_PERSONA_FAIL";

export const LIST_INDIVIDUAL_PERSONA_SUCCESS =
  "LIST_INDIVIDUAL_PERSONA_SUCCESS";
export const LIST_INDIVIDUAL_PERSONA_FAIL = "LIST_INDIVIDUAL_PERSONA_FAIL";

export const LIST_FACILITATOR_PERSONA_SUCCESS =
  "LIST_FACILITATOR_PERSONA_SUCCESS";
export const LIST_FACILITATOR_PERSONA_FAIL = "LIST_FACILITATOR_PERSONA_FAIL";

export const LOADING_PERSONA_DETAILS_SUCCESS =
  "LOADING_PERSONA_DETAILS_SUCCESS";

export const PERSONA_DETAILS_SUCCESS = "PERSONA_DETAILS_SUCCESS";
export const PERSONA_DETAILS_FAIL = "PERSONA_DETAILS_FAIL";
export const LOADING_WALLET_HISTORY_SUCCESS = "LOADING_WALLET_HISTORY_SUCCESS";

export const FUEL_PURCHASE_SUCCESS = "FUEL_PURCHASE_SUCCESS";
export const FUEL_PURCHASE_FAIL = "FUEL_PURCHASE_FAIL";
export const LOADING_REPORT_SUCCESS = "LOADING_REPORT_SUCCESS";

export const SYSTEM_USER_DETAILS_SUCCESS = "SYSTEM_USER_DETAILS_SUCCESS";
export const SYSTEM_USER_DETAILS_FAIL = "SYSTEM_USER_DETAILS_FAIL";
export const SYSTEM_USER_LOADING = "SYSTEM_USER_LOADING";

// export const RESET_TO_FALSE = "RESET_TO_FALSE";

export const updateUserInfo = () => async (dispatch) => {
  const body = {
    clientId: "WEBAPP",
    // walletId: walletId,
  };
  try {
    const data = await axiosServices.post(`/updateIndividualUserDetail`, body);
    if (data.responseCode === "00") {
      // dispatch({
      //   type: "UPDATE_USER_SUCCESS",
      //   payload: data.info?.Meaasge,
      // });
      console.log("UPDATE_USER_SUCCESS", data.info);
    } else {
      // dispatch({
      //   type: UPDATE_USER_FAIL,
      // });
    }
  } catch (error) {
    // dispatch({
    //   type: UPDATE_USER_FAIL,
    // });
  }
};

export const getWalletBalance = (walletId) => async (dispatch) => {
  const body = {
    clientId: "WEBAPP",
    walletId: walletId,
  };

  try {
    const data = await axiosServices.post(`/walletBalance`, body);

    if (data.responseCode === "00") {
      dispatch({
        type: "WALLET_BALANCE_SUCCESS",
        payload: data.info?.Meaasge,
      });
      console.log("WALLET_BALANCE_SUCCESS", data.info);
    } else {
      dispatch({
        type: WALLET_BALANCE_FAIL,
      });
    }
  } catch (error) {
    dispatch({
      type: WALLET_BALANCE_FAIL,
    });
  }
};

export const getUserDetail = () => async (dispatch) => {
  try {
    const res = await axiosServices.get(`/user/details`);

    // return
    dispatch({
      type: "USER_SUCCESS",
      payload: res,
    });
    console.log("res", res);
  } catch (error) {
    dispatch({
      type: USER_FAIL,
    });
  }
};

export const getSupervisorDetail = () => async (dispatch) => {
  dispatch({
    type: "SUPERVISOR_DASHBOARD_DETAILS_LOADING",
    payload: true,
  });
  try {
    const res = await axiosServices.get(`/supervisors/my-profile`);
    // return
    dispatch({
      type: "SUPERVISOR_DASHBOARD_DETAILS_SUCCESS",
      payload: res,
    });
    console.log("res", res);
    dispatch({
      type: "SUPERVISOR_DASHBOARD_DETAILS_LOADING",
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: USER_FAIL,
    });
    dispatch({
      type: "SUPERVISOR_DASHBOARD_DETAILS_LOADING",
      payload: false,
    });
  }
};

export const getCorporateUserDetail = () => async (dispatch) => {
  const body = {
    clientId: "WEBAPP",
    emailAddress: localStorage.getItem("access_email"),
  };
  console.log("body", body);

  try {
    const data = await axiosServices.post(`/getCorporateUserDetail`, body);
    const res = JSON.parse(data?.info);

    if (res.responseCode === "98" || res.responseCode === "96") {
      dispatch({
        type: USER_FAIL,
      });
    } else {
      dispatch({
        type: "USER_SUCCESS",
        payload: res,
      });
      console.log("res", res);
    }
  } catch (error) {
    dispatch({
      type: USER_FAIL,
    });
  }
};

export const getRetailerUserDetail = () => async (dispatch) => {
  const body = {
    clientId: "WEBAPP",
    emailAddress: localStorage.getItem("access_email"),
  };
  console.log("body", body);

  try {
    const data = await axiosServices.post(`/getRetailerUserDetail`, body);
    const res = JSON.parse(data?.info);

    if (res.responseCode === "98" || res.responseCode === "96") {
      dispatch({
        type: USER_FAIL,
      });
    } else {
      dispatch({
        type: "USER_SUCCESS",
        payload: res,
      });
      console.log("res", res);
    }
  } catch (error) {
    dispatch({
      type: USER_FAIL,
    });
  }
};

export const getIndividualUserDetail = () => async (dispatch) => {
  const body = {
    clientId: "WEBAPP",
    emailAddress: localStorage.getItem("access_email"),
  };
  console.log("body", body);

  try {
    const data = await axiosServices.post(`/getIndividualUserDetail`, body);
    // const res = JSON.parse(data?.info);

    if (data.responseCode === "98" || data.responseCode === "96") {
      dispatch({
        type: USER_FAIL,
      });
    } else {
      dispatch({
        type: "USER_SUCCESS",
        payload: data.info,
      });
      // console.log("res ind", data);
    }
  } catch (error) {
    dispatch({
      type: USER_FAIL,
    });
  }
};

export const getGroup = (payload) => async (dispatch) => {
  const { keyword } = payload;
  try {
    const res = await axiosServices.get(
      `/groups/created-groups?group_name=${keyword}`
    );
    dispatch({
      type: LIST_GROUP_SUCCESS,
      payload: res.data,
    });
    console.log("res", res);
  } catch (error) {
    dispatch({
      type: LIST_GROUP_FAIL,
    });
  }
};

export const getSupervisorsGroup = () => async (dispatch) => {
  try {
    const res = await axiosServices.get(`/supervisors/my-groups`);
    dispatch({
      type: LIST_GROUP_SUCCESS,
      payload: res.data,
    });
    console.log("res", res);
  } catch (error) {
    dispatch({
      type: LIST_GROUP_FAIL,
    });
  }
};

export const getGroupDetails =
  ({ id }) =>
  async (dispatch) => {
    try {
      const res = await axiosServices.get(`/groups/details/${id}`);
      dispatch({
        type: "GROUP_DETAILS_SUCCESS",
        payload: res.data,
      });
      console.log("res", res);
    } catch (error) {
      dispatch({
        type: LIST_GROUP_FAIL,
      });
    }
  };

export const getSupervisor = () => async (dispatch) => {
  try {
    const res = await axiosServices.get(`/supervisors/created-supervisors`);
    dispatch({
      type: "LIST_SUPERVISOR_SUCCESS",
      payload: res.data,
    });
    // console.log("res", res);
  } catch (error) {
    dispatch({
      type: LIST_SUPERVISOR_FAIL,
    });
  }
};

// getVehicle
export const getVehicle = () => async (dispatch) => {
  try {
    const res = await axiosServices.get(`/vehicles/created-vehicles`);
    dispatch({
      type: "LIST_VEHICLE_SUCCESS",
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: LIST_VEHICLE_FAIL,
    });
  }
};

export const vehicleStatus = (plate_number, new_status) => async (dispatch) => {
  const body = {
    plate_number: plate_number,
    new_status: new_status,
  };
  try {
    const res = await axiosServices.post(`/vehicles/change-status`, body);
    console.log("res", res);
    const res2 = await axiosServices.get(`/vehicles/created-vehicles`);
    dispatch({
      type: "LIST_VEHICLE_SUCCESS",
      payload: res2.data,
    });
  } catch (error) {
    dispatch({
      type: LIST_VEHICLE_FAIL,
    });
  }
};

export const getIndividualVehicle = (page, per_page) => async (dispatch) => {
  try {
    const res = await axiosServices.get(`/individual/my-created-vehicles?page=${page}&per_page=${per_page}`);
    dispatch({
      type: "LIST_INDIVIDUAL_VEHICLE_SUCCESS",
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: LIST_INDIVIDUAL_VEHICLE_FAIL,
    });
  }
};

export const vehicleStatusIndividual =
  (plate_number, new_status) => async (dispatch) => {
    const body = {
      plate_number: plate_number,
      new_status: new_status,
    };
    try {
      const res = await axiosServices.post(`/vehicles/change-status`, body);
      console.log("res", res);
      const res2 = await axiosServices.get(`/individual/my-created-vehicles`);
      dispatch({
        type: "LIST_INDIVIDUAL_VEHICLE_SUCCESS",
        payload: res2.data,
      });
    } catch (error) {
      dispatch({
        type: LIST_INDIVIDUAL_VEHICLE_FAIL,
      });
    }
  };

// getVehicle
export const getAttendant = () => async (dispatch) => {
  dispatch({
    type: ATTENDANT_LOADING,
    payload: true,
  });
  try {
    const res = await axiosServices.get(`/retailer/my-created-attendants`);
    console.log("res hghgu", res?.data?.data);
    localStorage.setItem("attendant", JSON.stringify(res.data?.data));
    dispatch({
      type: LIST_ATTENDANT_SUCCESS,
      payload: res?.data,
    });
    dispatch({
      type: ATTENDANT_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: LIST_ATTENDANT_FAIL,
    });
    dispatch({
      type: ATTENDANT_LOADING,
      payload: false,
    });
  }
};

export const getUserManagementDatas = () => async (dispatch) => {
  try {
    const axiosrequest1 = axiosServices.get(
      "/reports/users/all?persona=INDIVIDUAL"
    );
    const axiosrequest2 = axiosServices.get(
      "/reports/users/all?persona=retailer"
    );
    const axiosrequest3 = axiosServices.get(
      "/reports/users/all?persona=facilitator"
    );
    const axiosrequest4 = axiosServices.get(
      "/reports/users/all?persona=CORPORATE"
    );
    // you could also use destructuring to have an array of responses
    await axios
      .all([axiosrequest1, axiosrequest2, axiosrequest3, axiosrequest4])
      .then(
        axios.spread(function (res1, res2, res3, res4) {
          console.log("res1", res1);
          console.log("res2", res2);
          console.log("res3", res3);
          console.log("res4", res4);
          dispatch({
            type: "USER_MANAGEMENT_SUCCESS",
            payload: {
              individual: res1.data,
              retailer: res2.data,
              facilitator: res3.data,
              corporate: res4.data,
            },
          });
        })
      );
  } catch (error) {
    console.log("first error", error);
  }
};

export const getDashboardDetails = () => async (dispatch) => {
  try {
    const res = await axiosServices.get(`/reports/dashboard-details`);
    dispatch({
      type: DASHBOARD_DETAILS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: "DASHBOARD_DETAILS_FAIL",
    });
  }
};

// getVehicle
export const getCorporatePersona = (page) => async (dispatch) => {
  dispatch({
    type: "LOADING_PERSONA_DETAILS_SUCCESS",
    payload: true,
  });
  try {
    const res = await axiosServices.get(
      `/reports/users/all?persona=CORPORATE&per_page=10&page=${page}`
    );
    // console.log("res hghgu", res?.data);
    dispatch({
      type: LIST_CORPORATE_PERSONA_SUCCESS,
      payload: res,
    });
    dispatch({
      type: "LOADING_PERSONA_DETAILS_SUCCESS",
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: LIST_CORPORATE_PERSONA_FAIL,
    });
    dispatch({
      type: "LOADING_PERSONA_DETAILS_SUCCESS",
      payload: false,
    });
  }
};

export const getIndividualPersona = () => async (dispatch) => {
  dispatch({
    type: "LOADING_PERSONA_DETAILS_SUCCESS",
    payload: true,
  });
  try {
    const res = await axiosServices.get(
      `/reports/users/all?persona=INDIVIDUAL`
    );
    // console.log("res hghgu", res?.data);
    dispatch({
      type: LIST_CORPORATE_PERSONA_SUCCESS,
      payload: res,
    });
    dispatch({
      type: "LOADING_PERSONA_DETAILS_SUCCESS",
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: LIST_CORPORATE_PERSONA_FAIL,
    });
    dispatch({
      type: "LOADING_PERSONA_DETAILS_SUCCESS",
      payload: false,
    });
  }
};

export const getFuelRetailerPersona = () => async (dispatch) => {
  dispatch({
    type: "LOADING_PERSONA_DETAILS_SUCCESS",
    payload: true,
  });
  try {
    const res = await axiosServices.get(`/reports/users/all?persona=retailer`);
    // console.log("res hghgu", res?.data);
    dispatch({
      type: LIST_CORPORATE_PERSONA_SUCCESS,
      payload: res,
    });
    dispatch({
      type: "LOADING_PERSONA_DETAILS_SUCCESS",
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: LIST_CORPORATE_PERSONA_FAIL,
    });
    dispatch({
      type: "LOADING_PERSONA_DETAILS_SUCCESS",
      payload: false,
    });
  }
};

export const getFacilitatorPersona = () => async (dispatch) => {
  dispatch({
    type: "LOADING_PERSONA_DETAILS_SUCCESS",
    payload: true,
  });
  try {
    const res = await axiosServices.get(
      `/reports/users/all?persona=facilitator`
    );
    // console.log("res hghgu", res?.data);
    dispatch({
      type: LIST_CORPORATE_PERSONA_SUCCESS,
      payload: res,
    });
    dispatch({
      type: "LOADING_PERSONA_DETAILS_SUCCESS",
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: LIST_CORPORATE_PERSONA_FAIL,
    });
    dispatch({
      type: "LOADING_PERSONA_DETAILS_SUCCESS",
      payload: false,
    });
  }
};

export const getDetailsOfPersona = (id, persona) => async (dispatch) => {
  dispatch({
    type: "LOADING_DETAILS_SUCCESS",
    payload: true,
  });
  try {
    const res = await axiosServices.get(
      `/reports/users/detail?user_id=${id}&persona=${persona}`
    );
    console.log("res hghgu", res?.data);
    dispatch({
      type: PERSONA_DETAILS_SUCCESS,
      payload: res,
    });
    dispatch({
      type: "LOADING_DETAILS_SUCCESS",
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: PERSONA_DETAILS_FAIL,
    });
    dispatch({
      type: "LOADING_DETAILS_SUCCESS",
      payload: false,
    });
  }
};

export const getWalletHistory = (payload) => async (dispatch) => {
  const { keyword, fiter_type, page, per_page } = payload;
  dispatch({
    type: "LOADING_WALLET_HISTORY_SUCCESS",
    payload: true,
  });
  try {
    const res = await axiosServices.get(
      `/wallet/history?per_page=${per_page}&page=${page}`
    );
    console.log("res hghgu", res?.data);
    dispatch({
      type: WALLET_HISTORY_SUCCESS,
      payload: res,
    });
    dispatch({
      type: "LOADING_WALLET_HISTORY_SUCCESS",
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: WALLET_HISTORY_FAIL,
    });
    dispatch({
      type: "LOADING_WALLET_HISTORY_SUCCESS",
      payload: false,
    });
  }
};

export const getWalletDeposits = (payload) => async (dispatch) => {
  const { keyword, fiter_type, page, per_page } = payload;
  dispatch({
    type: "LOADING_WALLET_DEPOSIT_SUCCESS",
    payload: true,
  });
  try {
    const res = await axiosServices.get(
      `/wallet/deposits?per_page=${per_page}&page=${page}&keyword=${keyword}`
    );
    // &keyword=ONEFUEL-COR-1660420956
    console.log("res hghgu", res?.data);
    dispatch({
      type: WALLET_DEPOSIT_SUCCESS,
      payload: res,
    });
    dispatch({
      type: "LOADING_WALLET_DEPOSIT_SUCCESS",
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: WALLET_DEPOSIT_FAIL,
    });
    dispatch({
      type: "LOADING_WALLET_DEPOSIT_SUCCESS",
      payload: false,
    });
  }
};

export const getAdminReports =
  (page, per_page, type, start_date, end_date, ref) => async (dispatch) => {
    dispatch({
      type: LOADING_REPORT_SUCCESS,
      payload: true,
    });
    try {
      const res = await axiosServices.get(
        `/reports/fuel-history?page=${page}&per_page=${per_page}&type=${type}&start_date=${start_date}&end_date=${end_date}&keyword=${ref}`
      );
      console.log("res hghgu", res?.data);
      dispatch({
        type: FUEL_PURCHASE_SUCCESS,
        payload: res,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
      console.log("REPORTS", res);
    } catch (error) {
      dispatch({
        type: FUEL_PURCHASE_FAIL,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
    }
  };

export const getAdminReportsWithPersona =
  (page, per_page, type, start_date, end_date, persona, ref) =>
  async (dispatch) => {
    dispatch({
      type: LOADING_REPORT_SUCCESS,
      payload: true,
    });
    try {
      const res = await axiosServices.get(
        `/reports/fuel-history?page=${page}&per_page=${per_page}&type=${type}&start_date=${start_date}&end_date=${end_date}&persona=${persona}&keyword=${ref}`
      );
      console.log("res hghgu", res?.data);
      dispatch({
        type: FUEL_PURCHASE_SUCCESS,
        payload: res,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
      console.log("REPORTS", res);
    } catch (error) {
      dispatch({
        type: FUEL_PURCHASE_FAIL,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
    }
  };

export const getPrimaryWallet = (user_id) => async (dispatch) => {
  dispatch({
    type: LOADING_REPORT_SUCCESS,
    payload: true,
  });
  try {
    const res = await axiosServices.get(
      `/reports/wallet-details?user_id=${user_id}`
      // pe=${type}&start_date=${start_date}&end_date=${end_date}`
    );
    console.log("res hghgu", res?.data);
    dispatch({
      type: FUEL_PURCHASE_SUCCESS,
      payload: res,
    });
    dispatch({
      type: LOADING_REPORT_SUCCESS,
      payload: false,
    });
    console.log("REPORTS", res);
  } catch (error) {
    dispatch({
      type: FUEL_PURCHASE_FAIL,
    });
    dispatch({
      type: LOADING_REPORT_SUCCESS,
      payload: false,
    });
  }
};

// /reports/onefuel-commission-wallet-inflow?page=1&per_page=10&start_date=01-01-2022&end_date=20-12-2022
export const onefuelCommissionWalletInfo =
  (page, per_page, type, start_date, end_date) => async (dispatch) => {
    dispatch({
      type: LOADING_REPORT_SUCCESS,
      payload: true,
    });
    try {
      const res = await axiosServices.get(
        `/reports/onefuel-commission-wallet-inflow?page=${page}&per_page=${per_page}&type=${type}&start_date=${start_date}&end_date=${end_date}`
      );
      console.log("res hghgu", res?.data);
      dispatch({
        type: FUEL_PURCHASE_SUCCESS,
        payload: res,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
      console.log("REPORTS", res);
    } catch (error) {
      dispatch({
        type: FUEL_PURCHASE_FAIL,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
    }
  };

// /reports/onefuel-commission-wallet-outflow?page=1&per_page=10&start_date=01-01-2022&end_date=20-12-2022&persona=ONEFUEL
export const onefuelCommissionWalletOutflow =
  (page, per_page, type, start_date, end_date, persona) => async (dispatch) => {
    dispatch({
      type: LOADING_REPORT_SUCCESS,
      payload: true,
    });
    try {
      const res = await axiosServices.get(
        `/reports/onefuel-commission-wallet-outflow?page=${page}&per_page=${per_page}&type=${type}&start_date=${start_date}&end_date=${end_date}&persona=${persona}`
      );
      console.log("res hghgu", res?.data);
      dispatch({
        type: FUEL_PURCHASE_SUCCESS,
        payload: res,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
      console.log("REPORTS", res);
    } catch (error) {
      dispatch({
        type: FUEL_PURCHASE_FAIL,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
    }
  };

// /reports/wallet-deposits?user_id=10&page=1&per_page=10&start_date=01-01-2022&end_date=20-12-2022
export const getAdminReportsForCorporate =
  (page, per_page, type, start_date, end_date, id) => async (dispatch) => {
    dispatch({
      type: LOADING_REPORT_SUCCESS,
      payload: true,
    });
    try {
      const res = await axiosServices.get(
        `/reports/wallet-deposits?user_id=${id}&page=${page}&per_page=${per_page}&type=${type}&start_date=${start_date}&end_date=${end_date}`
      );
      console.log("res hghgu", res?.data);
      dispatch({
        type: FUEL_PURCHASE_SUCCESS,
        payload: res,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
      console.log("REPORTS", res);
    } catch (error) {
      dispatch({
        type: FUEL_PURCHASE_FAIL,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
    }
  };

export const getReportsIndividual =
  (page, per_page, type, start_date, end_date, keyword) => async (dispatch) => {
    dispatch({
      type: LOADING_REPORT_SUCCESS,
      payload: true,
    });
    try {
      const res = await axiosServices.get(
        `/reports/individual-fuel-history?page=${page}&per_page=${per_page}
        &type=${type}&start_date=${start_date}&end_date=${end_date}&keyword=${keyword}`
      );
      console.log("res hghgu", res?.data);
      dispatch({
        type: FUEL_PURCHASE_SUCCESS,
        payload: res,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
      console.log("REPORTS", res);
    } catch (error) {
      dispatch({
        type: FUEL_PURCHASE_FAIL,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
    }
  };

export const getReportsRetailer =
  (page, per_page, type, start_date, end_date, keyword) => async (dispatch) => {
    dispatch({
      type: LOADING_REPORT_SUCCESS,
      payload: true,
    });
    try {
      const res = await axiosServices.get(
        `/reports/dispense-report?page=${page}&per_page=${per_page}
        &type=${type}&start_date=${start_date}&end_date=${end_date}&keyword=${keyword}`
      );
      console.log("res hghgu", res?.data);
      dispatch({
        type: FUEL_PURCHASE_SUCCESS,
        payload: res,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
      console.log("REPORTS", res);
    } catch (error) {
      dispatch({
        type: FUEL_PURCHASE_FAIL,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
    }
  };

// /fuel/supervisors-fuel-assigned?start_date=01-01-2022&end_date=20-12-2022&is_download=true
export const getSupervisorPurchaseReport =
  (page, per_page, type, start_date, end_date) => async (dispatch) => {
    dispatch({
      type: LOADING_REPORT_SUCCESS,
      payload: true,
    });
    try {
      const res = await axiosServices.get(
        `/fuel/supervisors-fuel-assigned?page=${page}&per_page=${per_page}&type=${type}&start_date=${start_date}&end_date=${end_date}`
      );
      console.log("res hghgu", res?.data);
      dispatch({
        type: FUEL_PURCHASE_SUCCESS,
        payload: res,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
      console.log("REPORTS", res);
    } catch (error) {
      dispatch({
        type: FUEL_PURCHASE_FAIL,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
    }
  };

export const getDailySales =
  (page, per_page, type, start_date, end_date) => async (dispatch) => {
    dispatch({
      type: LOADING_REPORT_SUCCESS,
      payload: true,
    });
    try {
      const res = await axiosServices.get(
        `/reports/dispense-report?page=${page}&per_page=${per_page}&type=${type}&start_date=${start_date}&end_date=${end_date}`
      );
      console.log("res hghgu", res?.data);
      dispatch({
        type: FUEL_PURCHASE_SUCCESS,
        payload: res,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
      console.log("REPORTS", res);
    } catch (error) {
      dispatch({
        type: FUEL_PURCHASE_FAIL,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
    }
  };

export const getReportsCorporate =
  (page, per_page, type, start_date, end_date, keyword) => async (dispatch) => {
    dispatch({
      type: LOADING_REPORT_SUCCESS,
      payload: true,
    });
    try {
      const res = await axiosServices.get(
        `/reports/coperate-fuel-history?page=${page}&per_page=${per_page}&type=${type}&start_date=${start_date}&end_date=${end_date}&keyword=${keyword}`
      );
      console.log("res hghgu", res?.data);
      dispatch({
        type: FUEL_PURCHASE_SUCCESS,
        payload: res,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
      console.log("REPORTS", res);
    } catch (error) {
      dispatch({
        type: FUEL_PURCHASE_FAIL,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
    }
  };

export const getFuelSupervisorPurchaseHistory =
  (page, per_page, type, start_date, end_date) => async (dispatch) => {
    dispatch({
      type: LOADING_REPORT_SUCCESS,
      payload: true,
    });
    try {
      const res = await axiosServices.get(
        `/fuel/supervisors-fuel-assigned?page=${page}&per_page=${per_page}&type=${type}&start_date=${start_date}&end_date=${end_date}`
      );
      console.log("res hghgu", res?.data);
      dispatch({
        type: FUEL_PURCHASE_SUCCESS,
        payload: res,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
      console.log("REPORTS", res);
    } catch (error) {
      dispatch({
        type: FUEL_PURCHASE_FAIL,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
    }
  };

export const getFuelSupervisorDispenseHistory =
  (page, per_page, type, start_date, end_date) => async (dispatch) => {
    dispatch({
      type: LOADING_REPORT_SUCCESS,
      payload: true,
    });
    try {
      const res = await axiosServices.get(
        `/fuel/supervisors-fuel-dispensed?page=${page}&per_page=${per_page}&type=${type}&start_date=${start_date}&end_date=${end_date}`
      );
      console.log("res hghgu", res?.data);
      dispatch({
        type: FUEL_PURCHASE_SUCCESS,
        payload: res,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
      console.log("REPORTS", res);
    } catch (error) {
      dispatch({
        type: FUEL_PURCHASE_FAIL,
      });
      dispatch({
        type: LOADING_REPORT_SUCCESS,
        payload: false,
      });
    }
  };

export const getAuditTrail = (page, per_page) => async (dispatch) => {
  try {
    const res = await axiosServices.get(`/reports/audit?per_page=10&page=1`);
    dispatch({
      type: "LIST_SUPERVISOR_SUCCESS",
      payload: res.data,
    });
    // console.log("res", res);
  } catch (error) {
    dispatch({
      type: LIST_SUPERVISOR_FAIL,
    });
  }
};

export const getAllSystemUsers = (per_page, page) => async (dispatch) => {
  try {
    const res = await axiosServices.get(
      `/permissions/users/all?per_page=${page}&page=${per_page}`
    );
    dispatch({
      type: "LIST_SUPERVISOR_SUCCESS",
      payload: res.data,
    });
    console.log("res", res);
  } catch (error) {
    dispatch({
      type: LIST_SUPERVISOR_FAIL,
    });
  }
};

export const getSystemUserDetails = (id) => async (dispatch) => {
  dispatch({
    type: SYSTEM_USER_LOADING,
    payload: true,
  });
  try {
    const res = await axiosServices.get(
      `/permissions/user-permissions?user_id=${id}`
    );
    dispatch({
      type: "SYSTEM_USER_DETAILS_SUCCESS",
      payload: res.data,
    });
    dispatch({
      type: SYSTEM_USER_LOADING,
      payload: false,
    });
    console.log("res", res);
  } catch (error) {
    dispatch({
      type: SYSTEM_USER_DETAILS_FAIL,
    });
    dispatch({
      type: SYSTEM_USER_LOADING,
      payload: false,
    });
  }
};
