import React from "react";
import "./AssignFuel.scss";
import { Formik } from "formik";
import Spinner from "react-bootstrap/Spinner";
import axiosServices from "../../../../../services/axiosServices";
import { toast } from "react-toastify";

const AssignFuel = ({ toggleForward }) => {
  return (
    <div className='cr-section'>
      <div className='cr-section-header'>
        <h3 className='cr-section-header-title'>Assign Fuel Limit</h3>
        <p className='cr-section-header-subtitle'>
          Fill in the registration data. It will take a couple of minutes.{" "}
        </p>
      </div>
      <div className='cr-section-body'>
        <div className='cr-section-body-content'>
          <Formik
            initialValues={{
              monthlyLimit: 0,
              dailyLimit: 0,
              vehicleRegNo: 0,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.monthlyLimit) {
                errors.monthlyLimit = "Monthly limit is required";
              }
              if (!values.dailyLimit) {
                errors.dailyLimit = "Daily Limit is required";
              }
              if (!values.vehicleRegNo) {
                errors.vehicleRegNo = "Vehicle RegNo is required";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const body = {
                clientId: "WEBAPP",
                emailAddress: localStorage.getItem("access_email"),
                monthlyLimit: values.monthlyLimit,
                dailyLimit: values.dailyLimit,
                vehicleRegNo: values.vehicleRegNo,
              };

              try {
                const res = await axiosServices.post(`/assignFuelLimit`, body);
                if (res.responseCode === "98" || res.responseCode === "96") {
                  toast.error("Fuel Limit Assigned Failed");
                  // toggleForward();
                  setSubmitting(false);
                } else {
                  setSubmitting(false);
                  toast.success("Fuel Limit Assigned Successfully");
                  toggleForward();
                  console.log("registration", res);
                }
              } catch (error) {
                const errors = {};
                for (const key in error.response.data.errors) {
                  errors[key] = error.response.data.errors[key][0];
                }
                setSubmitting(false);
                console.log("error", error.response.data.errors);
                for (const key in errors) {
                  toast.error(errors[key]);
                }
                toggleForward();
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              dirty,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className='form_input_wrapper'>
                  <label>Monthly Limit</label>
                  <input
                    type='text'
                    name='monthlyLimit'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.monthlyLimit}
                    placeholder='Enter amount'
                    className={
                      errors.monthlyLimit && touched.monthlyLimit
                        ? "input-error"
                        : null
                    }
                  />
                  {errors.monthlyLimit && touched.monthlyLimit && (
                    <span className='error'>{errors.monthlyLimit}</span>
                  )}
                </div>

                <div className='form_input_wrapper'>
                  <label>Daily Limit</label>
                  <input
                    type='text'
                    name='dailyLimit'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.dailyLimit}
                    placeholder='Enter amount'
                    className={
                      errors.dailyLimit && touched.dailyLimit
                        ? "input-error"
                        : null
                    }
                  />
                  {errors.dailyLimit && touched.dailyLimit && (
                    <span className='error'>{errors.dailyLimit}</span>
                  )}
                </div>

                <div className='form_input_wrapper'>
                  <label>Vehicle RegNo</label>
                  <input
                    type='text'
                    name='vehicleRegNo'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.vehicleRegNo}
                    placeholder='Enter Vehicle RegNo'
                    className={
                      errors.vehicleRegNo && touched.vehicleRegNo
                        ? "input-error"
                        : null
                    }
                  />
                  {errors.vehicleRegNo && touched.vehicleRegNo && (
                    <span className='error'>{errors.vehicleRegNo}</span>
                  )}
                </div>

                <div className='login-btn'>
                  <button type='submit' disabled={!dirty}>
                    {isSubmitting ? (
                      <Spinner
                        as='span'
                        animation='border'
                        // size='sm'
                        role='status'
                        aria-hidden='true'
                        variant='light'
                      />
                    ) : (
                      "Save & Continue"
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AssignFuel;
