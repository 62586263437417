import axios from 'axios';
import { BASE_URL } from '../configs/api';


// Add a request interceptor
const axiosServices = axios.create({ BASE_URL });
// Request call
axiosServices.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("access_token");

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    config.url = BASE_URL + config.url;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response call
axiosServices.interceptors.response.use(
  (next) => {
    return Promise.resolve(next.data);
  },
  (error) => {
    if (error?.response?.status === 401) {
      // Add a modal pop, inform user session is expired
      localStorage.clear();
      window.location.href = "/";
    }
    // You can handle error here and trigger warning message without get in the code inside
    return Promise.reject(error);
  },
);

export default axiosServices;
