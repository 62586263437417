import React, { useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import "./Form.scss";
import { useSelector } from "react-redux";
import axiosServices from "../../services/axiosServices";

const ResetPasswordForm = () => {
  // const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  // let navigate = useNavigate();
  const [toogleFormDisplay, setToogleFormDisplay] = useState(true);
  const Auth = useSelector((state) => state.Auth);
  const navigate = useNavigate();

  if (Auth?.isAuthenticated) {
    return <Navigate to='/dashboard/home' />;
  }

  return (
    <div className='form__wrapper'>
      <div className='form__title'>
        <h2>Reset Password</h2>
        <p>
          Kindly fill in your registered email address below <br /> to recieve a
          reset link!
        </p>
      </div>

      <div className='form__body'>
        {toogleFormDisplay ? (
          <Formik
            initialValues={{ email: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Email is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setIsLoading(true);
              const body = {
                email: values.email,
              };
              // console.log("body", body);

              try {
                const res = await axiosServices.post(
                  `/user/request-verfication-code-no-auth`,
                  body
                );
                // console.log("password reset", res?.data);
                setIsLoading(false);
                navigate("/reset-password-otp");
              } catch (error) {
                setIsLoading(false);
                toast.error(error.response.data.message);
                setSubmitting(false);
                console.log("error", error.response.data.errors);
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              dirty,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className='form_input_wrapper reset-cta'>
                  <input
                    type='text'
                    name='email'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder='Enter your email address'
                    className={
                      errors.email && touched.email ? "input-error" : null
                    }
                  />
                  {errors.email && touched.email && (
                    <span className='error'>{errors.email}</span>
                  )}
                </div>

                <div className='login-btn '>
                  <button type='submit' disabled={!dirty}>
                    {isLoading ? (
                      <Spinner
                        as='span'
                        animation='border'
                        // size='sm'
                        role='status'
                        aria-hidden='true'
                        variant='light'
                      />
                    ) : (
                      "Continue"
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        ) : (
          <div className='form_input_wrapper resend-wrapper'>
            <h4 className='ctn-message'>
              Reset vefification code has been sent to
              <br /> oy********@gmail.com. Code expires in 15minutes!
            </h4>

            <div className='resend-container'>
              <p>
                Didn’t recieve mail?
                <span>
                  <svg
                    width='13'
                    height='13'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M13.6498 2.35C12.1998 0.9 10.2098 0 7.99977 0C3.57977 0 0.00976562 3.58 0.00976562 8C0.00976562 12.42 3.57977 16 7.99977 16C11.7298 16 14.8398 13.45 15.7298 10H13.6498C12.8298 12.33 10.6098 14 7.99977 14C4.68977 14 1.99977 11.31 1.99977 8C1.99977 4.69 4.68977 2 7.99977 2C9.65977 2 11.1398 2.69 12.2198 3.78L8.99977 7H15.9998V0L13.6498 2.35Z'
                      fill='#0D30B9'
                    />
                  </svg>{" "}
                  Send again
                </span>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordForm;
