import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import "./CompleteRegistration.scss";
import StateAndLga from "../../../../../Data/StateAndLga";
import axiosServices from "../../../../../services/axiosServices";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import ReuseSuccessModal from "../../../../../sharedComponent/modals/ReuseSuccessModal";
import { useSelector } from "react-redux";

const CompleteRegistration = ({ toggleForward }) => {
  const Auth = useSelector((state) => state.Auth);
  const id = Auth?.isUser?.corporateId;
  const [successModal, setSuccessModal] = useState(false);

  const [state, setState] = useState([]);
  const [lga, setLga] = useState([]);

  const text = "Congratulations! KYC Completed!";
  const text2 = "Register a vehicle to assign fuel and create a vehicle group!";

  const handleStateChange = (handleChange) => async (e) => {
    const id = e.target.value;
    handleChange(e);
    try {
      const res = await axiosServices.get(
        `/states/fetch-local-goverments-of-a-state?state_id=${id}`
      );
      setLga(res.data[0]?.local_goverments);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getstate = async (e) => {
    try {
      const res = await axiosServices.get("/states/all");
      console.log("res", res);
      setState(res.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getstate();
  }, []);

  return (
    <div className='cr-section'>
      {successModal && (
        <ReuseSuccessModal
          setSuccessModal={setSuccessModal}
          text={text}
          text2={text2}
          toggleForward={toggleForward}
        />
      )}
      <div className='cr-section-header update'>
        <h3 className='cr-section-header-title'>Complete Your Registration</h3>
        <p className='cr-section-header-subtitle'>
          Fill in the registration data. It will take a couple of minutes.{" "}
        </p>
      </div>
      <div className='cr-section-body'>
        <div className='cr-section-body-content'>
          <Formik
            initialValues={{
              officeAddress: "",
              phone_number: "",
              locationState: "",
              local_goverment_id: "",
              referral: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.officeAddress) {
                errors.officeAddress = "Required";
              }

              if (!values.locationState) {
                errors.locationState = "State is required";
              }

              if (!values.phone_number) {
                errors.phone_number = "Phone number is required";
              } else if (
                !/^[0-9]{11}$/i.test(values.phone_number) &&
                !/^[0-9]{10}$/i.test(values.phone_number)
              ) {
                errors.phone_number = "Invalid phone number";
              }

              if (!values.local_goverment_id) {
                errors.local_goverment_id = "Required";
              }

              // if (!values.referral) {
              //   errors.referral = "Required";
              // }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);

              const body = {
                business_address: values.officeAddress,
                locationState: values.locationState,
                local_goverment_id: values.local_goverment_id,
                phone_number: values.phone_number,
                referral: values.referral,
              };

              try {
                const res = await axiosServices.post(
                  `/user/update-account`,
                  body
                );
                toast.success("Registration Completed successfully");
                setSuccessModal(true);
                setSubmitting(false);
                toggleForward();
                console.log("registration", res);
              } catch (error) {
                const errors = {};
                for (const key in error.response.data.errors) {
                  errors[key] = error.response.data.errors[key][0];
                }
                setSubmitting(false);
                console.log("error", error.response.data.errors);
                for (const key in errors) {
                  toast.error(errors[key]);
                }
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              dirty,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className='form_input_wrapper'>
                  <label>Company’s Address</label>
                  <input
                    type='text'
                    name='officeAddress'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.officeAddress}
                    placeholder=''
                    className={
                      errors.officeAddress && touched.officeAddress
                        ? "input-error"
                        : null
                    }
                  />
                  {errors.officeAddress && touched.officeAddress && (
                    <span className='error'>{errors.officeAddress}</span>
                  )}
                </div>
                <div className='form_input_wrapper'>
                  <label>State of Residence</label>
                  <select
                    name='locationState'
                    onChange={handleStateChange(handleChange)}
                    onBlur={handleBlur}
                    value={values.locationState}
                    className={
                      errors.locationState && touched.locationState
                        ? "input-error"
                        : null
                    }
                  >
                    <option value=''>Select State</option>
                    {state &&
                      state.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                  </select>
                  {errors.locationState && touched.locationState && (
                    <span className='error'>{errors.locationState}</span>
                  )}
                </div>
                <div className='form_input_wrapper'>
                  <label>LGA</label>
                  <select
                    name='local_goverment_id'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.local_goverment_id}
                    className={
                      errors.local_goverment_id && touched.local_goverment_id
                        ? "input-error"
                        : null
                    }
                  >
                    <option value=''>Select LGA</option>
                    {lga &&
                      lga.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                  </select>
                  {errors.local_goverment_id && touched.local_goverment_id && (
                    <span className='error'>{errors.local_goverment_id}</span>
                  )}
                </div>

                <div className='form_input_wrapper'>
                  <label>Referal ID (Optional)</label>
                  <input
                    type='text'
                    name='referral'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.referral}
                    placeholder=''
                    className={
                      errors.referral && touched.referral ? "input-error" : null
                    }
                  />
                  {errors.referral && touched.referral && (
                    <span className='error'>{errors.referral}</span>
                  )}
                </div>

                <div className='form_input_wrapper'>
                  <label>Company’s Phone Number</label>
                  <input
                    type='text'
                    name='phone_number'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone_number}
                    placeholder=''
                    className={
                      errors.phone_number && touched.phone_number
                        ? "input-error"
                        : null
                    }
                  />
                  {errors.phone_number && touched.phone_number && (
                    <span className='error'>{errors.phone_number}</span>
                  )}
                </div>

                <div className='login-btn'>
                  <button type='submit' disabled={!dirty}>
                    {isSubmitting ? (
                      <Spinner
                        as='span'
                        animation='border'
                        // size='sm'
                        role='status'
                        aria-hidden='true'
                        variant='light'
                      />
                    ) : (
                      "Save & Continue"
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CompleteRegistration;
