import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import "./Form.scss";
import SuccessModal from "../../sharedComponent/modals/SuccessModal";
import axiosServices from "../../services/axiosServices";
import {
  ACCOUNT_ACTIVATION_FAILURE,
  ACCOUNT_ACTIVATION_SUCCESS,
} from "../../redux/actions/Auth";
import { useDispatch, useSelector } from "react-redux";

const RegisterOtpForm = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const Auth = useSelector((state) => state.Auth);
  const [otp, setOtp] = useState(0);
  const [successModal, setSuccessModal] = useState(false);

  const handleOtp = (otp) => {
    setOtp(otp);
  };

  // console.log("otp", otp.length);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("otp", otp);
    if (otp.length < 6) {
      toast.error("Please enter valid OTP");
      return;
    }
    setIsLoading(true);
    const body = {
      verfication_code: otp,
      email: localStorage.getItem("contactEmail"),
    };
    // console.log("body", body);

    try {
      const res = await axiosServices.post(`/user/activate-account`, body);
      dispatch({
        type: ACCOUNT_ACTIVATION_SUCCESS,
        payload: res.data,
      });
      // console.log(res);
      setIsLoading(false);
      navigate("/login");
      // localStorage.removeItem("contactEmail");
      // console.log("accountActivation", res?.data);
    } catch (error) {
      dispatch({
        type: ACCOUNT_ACTIVATION_FAILURE,
      });
      const errors = {};
      for (const key in error.response.data.errors) {
        errors[key] = error.response.data.errors[key][0];
      }
      // setSubmitting(false);
      console.log("error", error.response.data.errors);
      for (const key in errors) {
        toast.error(errors[key]);
      }
      // setSubmitting(false);
    }
  };

  const handleResendOtp = async () => {
    try {
      const res = await axiosServices.get(`/user/request-verfication-code`);
      toast.success("OTP has been sent to your email");
      // console.log("res", res);
    } catch (error) {
      const errors = {};
      for (const key in error.response.data.errors) {
        errors[key] = error.response.data.errors[key][0];
      }
      // setSubmitting(false);
      console.log("error", error.response.data.errors);
      for (const key in errors) {
        toast.error(errors[key]);
      }
    }
  };

  if (Auth?.isAuthenticated) {
    return <Navigate to='/' />;
  }

  return (
    <div className='form__wrapper'>
      {successModal && <SuccessModal setSuccessModal={setSuccessModal} />}
      <>
        <div className='form__title'>
          <h2>Verify your account!</h2>
          <p>
            Enter the four digit code sent to your registered email address.
          </p>
        </div>

        <div className='form__body'>
          <form onSubmit={handleSubmit}>
            <div className='otp register-otp'>
              <OtpInput
                onChange={handleOtp}
                value={otp}
                numInputs={6}
                separator={
                  <span>
                    <strong></strong>
                  </span>
                }
                inputStyle={{
                  width: "5rem",
                  height: "5rem",
                  margin: "0 2rem 0 0",
                  fontSize: "2rem",
                  borderRadius: 4,
                  border: "1px solid #0D30B9",
                  padding: "0.5rem",
                  background: "#F9FBFD",
                }}
              />
              <p className='resend-otp' onClick={() => handleResendOtp()}>
                <span>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M17.65 6.35C16.2 4.9 14.21 4 12 4C7.58001 4 4.01001 7.58 4.01001 12C4.01001 16.42 7.58001 20 12 20C15.73 20 18.84 17.45 19.73 14H17.65C16.83 16.33 14.61 18 12 18C8.69001 18 6.00001 15.31 6.00001 12C6.00001 8.69 8.69001 6 12 6C13.66 6 15.14 6.69 16.22 7.78L13 11H20V4L17.65 6.35Z'
                      fill='#0D30B9'
                    />
                  </svg>
                </span>
                Send again
              </p>
            </div>
            <p className='notice-msg'>
              Confirm account with code from email addreess
            </p>

            <div className='login-btn'>
              <button type='submit'>
                {isLoading ? (
                  <Spinner
                    as='span'
                    animation='border'
                    // size='sm'
                    role='status'
                    aria-hidden='true'
                    variant='light'
                  />
                ) : (
                  "Continue"
                )}
              </button>
            </div>
          </form>
        </div>
      </>
    </div>
  );
};

export default RegisterOtpForm;
