import React, { useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import usePasswordToggle from "../tooglePassword/usePasswordToggle";
import "./Form.scss";
import axiosServices from "../../services/axiosServices";

const ResetPasswordOtpForm = () => {
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <div className='form__wrapper'>
      <div className='form__title'>
        <h2>Change Password</h2>
      </div>

      <div className='form__body'>
        <Formik
          initialValues={{
            verfication_code: "",
            email: "",
            new_password: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Email is required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.password) {
              errors.password = "Password is required";
            } else if (values.password.length < 8) {
              errors.password = "Password must be at least 8 characters";
            }
            if (!values.verfication_code) {
              errors.verfication_code = "Verfication code is required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setIsLoading(true);
            const body = {
              email: values.email,
              new_password: values.password,
              verfication_code: values.verfication_code,
            };

            try {
              const res = await axiosServices.post(
                `/user/reset-password`,
                body
              );

              // console.log("res.data", res.data);
              setIsLoading(false);
              toast.success("Password Updated successfully");
              navigate("/login");
            } catch (error) {
              toast.error(error.response.data.message);
              setIsLoading(false);
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className='form_input_wrapper'>
                <label>Enter OTP</label>
                <input
                  type='number'
                  name='verfication_code'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.verfication_code}
                  placeholder='****'
                  className={
                    errors.verfication_code && touched.verfication_code
                      ? "input-error"
                      : null
                  }
                />
                {errors.verfication_code && touched.verfication_code && (
                  <span className='error'>{errors.verfication_code}</span>
                )}
              </div>
              <div className='form_input_wrapper'>
                <label>Email Address</label>
                <input
                  type='text'
                  name='email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder='Email'
                  className={
                    errors.email && touched.email ? "input-error" : null
                  }
                />
                {errors.email && touched.email && (
                  <span className='error'>{errors.email}</span>
                )}
              </div>
              <div className='form_input_wrapper password-input'>
                <label>New Password</label>
                <input
                  type={PasswordInputType}
                  name='password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  placeholder='Password'
                  className={
                    errors.password && touched.password ? "input-error" : null
                  }
                />
                <span className='password-toogle-icon'>{ToggleIcon}</span>
                {errors.password && touched.password && (
                  <span className='error'>{errors.password}</span>
                )}
              </div>

              <div className='login-btn'>
                <button type='submit'>
                  {isLoading ? (
                    <Spinner
                      as='span'
                      animation='border'
                      // size='sm'
                      role='status'
                      aria-hidden='true'
                      variant='light'
                    />
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPasswordOtpForm;
