import React from "react";
import "./CreateGroup.scss";
import { Formik } from "formik";
import Spinner from "react-bootstrap/Spinner";
import axiosServices from "../../../../../services/axiosServices";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const CreateGroup = ({ toggleForward }) => {
  const Auth = useSelector((state) => state.Auth);
  const id = Auth?.isUser?.corporateId;

  return (
    <div className='cr-section'>
      <div className='cr-section-header'>
        <h3 className='cr-section-header-title'>Create Group</h3>
        <p className='cr-section-header-subtitle'>
          Fill in the registration data. It will take a couple of minutes.{" "}
        </p>
      </div>
      <div className='cr-section-body'>
        <div className='cr-section-body-content'>
          <Formik
            initialValues={{
              groupName: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.groupName) {
                errors.groupName = "Required";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const body = {
                clientId: "WEBAPP",
                emailAddress: localStorage.getItem("access_email"),
                groupName: values.groupName,
                corporateId: id,
              };

              try {
                const res = await axiosServices.post(`/createGroup`, body);
                if (res.responseCode === "98" || res.responseCode === "96") {
                  toast.error("Group creation Failed");
                  setSubmitting(false);
                } else {
                  setSubmitting(false);
                  toast.success("Group created Successfully");
                  toggleForward();
                  console.log("registration", res);
                }
              } catch (error) {
                const errors = {};
                for (const key in error.response.data.errors) {
                  errors[key] = error.response.data.errors[key][0];
                }
                setSubmitting(false);
                console.log("error", error.response.data.errors);
                for (const key in errors) {
                  toast.error(errors[key]);
                }
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              dirty,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className='form_input_wrapper'>
                  <label>Group Name</label>
                  <input
                    type='text'
                    name='groupName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.groupName}
                    placeholder=''
                    className={
                      errors.groupName && touched.groupName
                        ? "input-error"
                        : null
                    }
                  />
                  {errors.groupName && touched.groupName && (
                    <span className='error'>{errors.groupName}</span>
                  )}
                </div>

                <div className='login-btn'>
                  <button type='submit' disabled={!dirty}>
                    {isSubmitting ? (
                      <Spinner
                        as='span'
                        animation='border'
                        // size='sm'
                        role='status'
                        aria-hidden='true'
                        variant='light'
                      />
                    ) : (
                      "Save & Continue"
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateGroup;
