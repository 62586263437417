import React from "react";
import { Formik } from "formik";
import "./VehicleRegistration.scss";
import { toast } from "react-toastify";
import axiosServices from "../../../../../services/axiosServices";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const vechicleType = ["Bus", "Truck", "Van", "Saloon car"];
const fuelType = ["PMS", "DIESEL"];

const VehicleRegistration = ({ toggleForward2 }) => {
  const navigate = useNavigate();
  return (
    <div className='cr-section'>
      <div className='cr-section-header'>
        <h3 className='cr-section-header-title'>Register your Vehicle(s)</h3>
        <p className='cr-section-header-subtitle'>
          Fill in the registration data. It will take a couple of minutes.{" "}
        </p>
      </div>
      <div className='cr-section-body'>
        <div className='cr-section-body-content'>
          <Formik
            initialValues={{
              vehicleType: "",
              vehicleModel: "",
              vehicleRegNo: "",
              fuelType: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.vehicleType) {
                errors.vehicleType = "Required";
              }
              if (!values.vehicleModel) {
                errors.vehicleModel = "vehicle Model is required";
              }
              if (!values.vehicleRegNo) {
                errors.vehicleRegNo = "vehicle RegNo is required";
              }
              if (!values.fuelType) {
                errors.fuelType = "fuel Type is required";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              const body = {
                fuel_type: values.fuelType,
                plate_number: values.vehicleRegNo,
                type: values.vehicleType,
                model: values.vehicleModel,
              };

              try {
                const res = await axiosServices.post(`/vehicles/create`, body);
                toast.success("Vehicle Registration Successful");
                console.log("registration", res);
                // toggleForward();
                navigate("/dashboard");
              } catch (error) {
                const errors = {};
                for (const key in error.response.data.errors) {
                  errors[key] = error.response.data.errors[key][0];
                }
                setSubmitting(false);
                console.log("error", error.response.data.errors);
                for (const key in errors) {
                  toast.error(errors[key]);
                }
                setSubmitting(false);
                console.log("error", error.response.data.errors);
                for (const key in errors) {
                  toast.error(errors[key]);
                }
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              dirty,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className='form_input_wrapper'>
                  <label>Vehicle Type</label>
                  <select
                    name='vehicleType'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.vehicleType}
                    className={
                      errors.vehicleType && touched.vehicleType
                        ? "input-error"
                        : null
                    }
                  >
                    <option value=''>Select vehicle type</option>
                    {vechicleType.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {errors.vehicleType && touched.vehicleType && (
                    <span className='error'>{errors.vehicleType}</span>
                  )}
                </div>

                <div className='form_input_wrapper'>
                  <label>Vehicle Model</label>
                  <input
                    type='text'
                    name='vehicleModel'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.vehicleModel}
                    placeholder=''
                    className={
                      errors.vehicleModel && touched.vehicleModel
                        ? "input-error"
                        : null
                    }
                  />
                  {errors.vehicleModel && touched.vehicleModel && (
                    <span className='error'>{errors.vehicleModel}</span>
                  )}
                </div>

                <div className='form_input_wrapper'>
                  <label>Vehicle plate number</label>
                  <input
                    type='text'
                    name='vehicleRegNo'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.vehicleRegNo}
                    placeholder=''
                    className={
                      errors.vehicleRegNo && touched.vehicleRegNo
                        ? "input-error"
                        : null
                    }
                  />
                  {errors.vehicleRegNo && touched.vehicleRegNo && (
                    <span className='error'>{errors.vehicleRegNo}</span>
                  )}
                </div>

                <div className='form_input_wrapper'>
                  <label>Fuel Type</label>
                  <select
                    name='fuelType'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fuelType}
                    className={
                      errors.fuelType && touched.fuelType ? "input-error" : null
                    }
                  >
                    <option value=''>Select vehicle type</option>
                    {fuelType.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {errors.fuelType && touched.fuelType && (
                    <span className='error'>{errors.fuelType}</span>
                  )}
                </div>

                <div className='login-btn'>
                  <button type='submit' disabled={!dirty}>
                    {isSubmitting ? (
                      <Spinner
                        as='span'
                        animation='border'
                        // size='sm'
                        role='status'
                        aria-hidden='true'
                        variant='light'
                      />
                    ) : (
                      "Save & Continue"
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default VehicleRegistration;
