import React, { useState } from "react";
import { Formik } from "formik";
import "./AttendantRegistration.scss";
import { toast } from "react-toastify";
import axiosServices from "../../../../../services/axiosServices";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";
import ReuseSuccessModal2 from "../../../../../sharedComponent/modals/ReuseSuccessModal2";

const AttendantRegistration = ({ toggleForward }) => {
  const Auth = useSelector((state) => state.Auth);
  const id = Auth?.isUser?.stationId;
  const [successModal, setSuccessModal] = useState(false);
  const text = "Registration Complete!";
  const text2 = "Attendant registration completed";

  return (
    <div className='cr-section'>
      <div className='cr-section-header'>
        {successModal && <ReuseSuccessModal2 text={text} text2={text2} />}
        <h3 className='cr-section-header-title'>Register your Vehicle(s)</h3>
        <p className='cr-section-header-subtitle'>
          Fill in the registration data. It will take a couple of minutes.{" "}
        </p>
      </div>
      <div className='cr-section-body'>
        <div className='cr-section-body-content'>
          <Formik
            initialValues={{
              deviceType: "",
              deviceId: "",
              devicePhoneNumber: "",
              locationState: "",
              stationAddress: "",
              stationLga: "",
              stationName: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.deviceType) {
                errors.deviceType = "Required";
              }
              if (!values.deviceId) {
                errors.deviceId = "Required";
              }
              if (!values.devicePhoneNumber) {
                errors.devicePhoneNumber = "Required";
              }
              if (!values.locationState) {
                errors.locationState = "Required";
              }
              if (!values.stationAddress) {
                errors.stationAddress = "Required";
              }
              if (!values.stationLga) {
                errors.stationLga = "Required";
              }
              if (!values.stationName) {
                errors.stationName = "Required";
              }
              // if (!values.password) {
              //   errors.password = "Required";
              // }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              // localStorage.setItem("contactEmail", values.email);

              //     {
              //       "clientId": "string",
              //       "createdBy": "string",
              // "deviceType": "string",
              // "deviceId": "string",
              // "devicedevicePhoneNumber": "string",
              // "locationState": "string",
              // "stationAddress": "string",
              // "stationId": "string",
              // "stationLga": "string",
              // "stationName": "string",
              // "virtualAccountRequired": true
              //     }

              const body = {
                clientId: "WEByAPP",
                stationId: id,
                createdBy: localStorage.getItem("access_email"),
                deviceType: values.deviceType,
                deviceId: values.deviceId,
                // password: values.password,
                devicePhoneNumber: values.devicePhoneNumber,
                locationState: values.locationState,
                stationAddress: values.stationAddress,
                stationLga: values.stationLga,
                stationName: values.stationName,
                virtualAccountRequired: true
              };

              try {
                const res = await axiosServices.post(
                  `/deviceRegistration`,
                  body
                );
                if (
                  res.responseCode === "98" ||
                  res.responseCode === "96" ||
                  res.responseCode === "97"
                ) {
                  toast.error("Attendant Registration Failed");
                  setSubmitting(false);
                } else {
                  setSubmitting(false);
                  toast.success("Attendant Registration Successful");
                  setSuccessModal(true);
                  console.log("registration", res);
                }
              } catch (error) {
                toast.error("Attendant Registration Failed");
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              dirty,
            }) => (
              <form onSubmit={handleSubmit}>

                <div className='form_input_wrapper'>
                  <label>State Name</label>
                  <input
                    type='text'
                    name='stationName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.stationName}
                  />
                  {errors.stationName && touched.stationName && (
                    <div className='input_feedback'>{errors.stationName}</div>
                  )}
                </div>

                <div className='form_input_wrapper'>
                  <label>State</label>
                  <input
                    type='text'
                    name='locationState'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.locationState}
                  />
                  {errors.locationState && touched.locationState && (
                    <div className='input_feedback'>{errors.locationState}</div>
                  )}
                </div>

                <div className='form_input_wrapper'>
                  <label>Station Address</label>
                  <input
                    type='text'
                    name='stationAddress'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.stationAddress}
                  />
                  {errors.stationAddress && touched.stationAddress && (
                    <div className='input_feedback'>{errors.stationAddress}</div>
                  )}
                </div>

                <div className='form_input_wrapper'>
                  <label>Station LGA</label>
                  <input
                    type='text'
                    name='stationLga'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.stationLga}
                  />
                  {errors.stationLga && touched.stationLga && (
                    <div className='input_feedback'>{errors.stationLga}</div>
                  )}
                </div>


                <div className='form_input_wrapper'>
                  <label>Device Type</label>
                  <input
                    type='text'
                    name='deviceType'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.deviceType}
                    className={
                      errors.deviceType && touched.deviceType
                        ? "form_input_error"
                        : "form_input"
                    }
                  />
                  {errors.deviceType && touched.deviceType && (
                    <div className='input_feedback'>{errors.deviceType}</div>
                  )}
                </div>

                <div className='form_input_wrapper'>
                  <label>Device Id</label>
                  <input
                    type='text'
                    name='deviceId'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.deviceId}
                    className={
                      errors.deviceId && touched.deviceId
                        ? "form_input_error"
                        : "form_input"
                    }
                  />
                  {errors.deviceId && touched.deviceId && (
                    <div className='input_feedback'>{errors.deviceId}</div>
                  )}
                </div>

                <div className='form_input_wrapper'>
                  <label className='form_label'>Phone Number</label>
                  <input
                    type='text'
                    name='devicePhoneNumber'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.devicePhoneNumber}
                    className={
                      errors.devicePhoneNumber && touched.devicePhoneNumber
                        ? "form_input_error"
                        : "form_input"
                    }
                  />
                  {errors.devicePhoneNumber && touched.devicePhoneNumber && (
                    <div className='input_feedback'>{errors.devicePhoneNumber}</div>
                  )}
                </div>
                {/* 
                <div className='form_input_wrapper'>
                  <label className='form_label'>Password</label>
                  <input
                    type='password'
                    name='password'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    className={
                      errors.password && touched.password
                        ? "form_input_error"
                        : "form_input"
                    }
                  />
                  {errors.password && touched.password && (
                    <div className='input_feedback'>{errors.password}</div>
                  )}
                </div> */}

                <div className='login-btn'>
                  <button type='submit' disabled={!dirty}>
                    {isSubmitting ? (
                      <Spinner
                        as='span'
                        animation='border'
                        // size='sm'
                        role='status'
                        aria-hidden='true'
                        variant='light'
                      />
                    ) : (
                      "Save & Continue"
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div >
  );
};

export default AttendantRegistration;
