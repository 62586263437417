import React from 'react'
import './ResetPasswordWithOtp.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import logo from "./../../../assets/images/logo-1.svg";
import OtpForm from '../../../components/form/OtpForm';
import ResetPasswordOtpForm from '../../../components/form/ResetPasswordOtpForm';

const ResetPasswordWithOtp = () => {
  return (
    <div className='page-wrapper'>
      <div className='left-side'>
        <div className='left-side-content-header'>
          <h3>
            Already have an account? <Link to='/registration'>Register</Link>
          </h3>
        </div>

        <div className='left-side-content-body'>
          <div className='left-side-content-body-text'>
            <FontAwesomeIcon icon={faQuoteLeft} className='faQuoteLeft' />
            <p className='text-content'>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
            </p>

            <p className='text-content-2'>
              Vincent Obi
              <FontAwesomeIcon icon={faCircleCheck} className='circle-check' />
            </p>

            <div className='last-icon-container'>
              <p></p>
              <p className='text-content-icon'>
                <svg
                  width='34'
                  height='33'
                  viewBox='0 0 34 33'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M21 0H33.5V33H0V20.5H21V0Z' fill='white' />
                </svg>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='right-side'>
        <div className='right-side-content-header'>
          <img src={logo} alt='logo' />
        </div>
        <div className='right-side-content-body'>
            <ResetPasswordOtpForm />
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordWithOtp