import React, { useState } from "react";
import { Formik } from "formik";
// import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import usePasswordToggle from "../tooglePassword/usePasswordToggle";
import "./Form.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  REGISTRATION_FAIL,
  REGISTRATION_SUCCESS,
} from "../../redux/actions/Auth";
import axiosServices from "../../services/axiosServices";
import { toast } from "react-toastify";

const RegisterFormCorporate = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [toogleFormDisplay, setToogleFormDisplay] = useState(true);
  const Auth = useSelector((state) => state.Auth);
  if (Auth?.isAuthenticated) {
    return <Navigate to='/dashboard/home' />;
  }

  return (
    <div className='form__wrapper'>
      <div className='form__title'>
        <h2>Register an Account!</h2>
        <p>
          For the purpose of industry regulation, your <br /> details are
          required.
        </p>
      </div>

      <div className='form__body'>
        <Formik
          initialValues={{
            corporateName: "",
            email: "",
            contactPerson: "",
            phoneNumber: "",
            user_email: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.corporateName) {
              errors.corporateName = "Required";
            }
            if (!values.email) {
              errors.email = "Company email is required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.password) {
              errors.password = "Password is required";
            } else if (values.password.length < 8) {
              errors.password = "Password must be at least 8 characters";
            }
            if (!values.confirmPassword) {
              errors.confirmPassword = "Confirm password is required";
            } else if (values.confirmPassword !== values.password) {
              errors.confirmPassword = "Password does not match";
            }
            if (!values.contactPerson) {
              errors.contactPerson = "Contact person is required";
            }
            if (!values.phoneNumber) {
              errors.phoneNumber = "Phone number is required";
            } else if (
              !/^[0-9]{11}$/i.test(values.phoneNumber) &&
              !/^[0-9]{10}$/i.test(values.phoneNumber)
            ) {
              errors.phoneNumber = "Invalid phone number";
            }
            if (!values.user_email) {
              errors.user_email = "Contact person email is required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.user_email)
            ) {
              errors.user_email = "Invalid user email address";
            }
            if (!values.terms) {
              errors.terms = "You must agree to the terms and conditions";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            localStorage.setItem("contactEmail", values.email);
            const body = {
              company_name: values.corporateName,
              contact_person_name: values.contactPerson,
              email: values.email,
              password: values.password,
              contact_person_phone: values.phoneNumber,
              user_email: values.user_email,
            };

            try {
              const res = await axiosServices.post(
                `/user/create-an-account/corporate`,
                body
              );
              dispatch({
                type: REGISTRATION_SUCCESS,
                payload: res.data,
              });
              setSubmitting(false);
              navigate("/verify-account");
              // console.log("registration", res);
              localStorage.setItem("access_token", res.data.token);
            } catch (error) {
              dispatch({
                type: REGISTRATION_FAIL,
              });
              const errors = {};
                for (const key in error.response.data.errors) {
                  errors[key] = error.response.data.errors[key][0];
                }
                setSubmitting(false);
                console.log("error", error.response.data.errors);
                for (const key in errors) {
                  toast.error(errors[key]);
                }
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
          }) => (
            <form onSubmit={handleSubmit}>
              {toogleFormDisplay ? (
                <>
                  <div className='form_input_wrapper'>
                    <label>Company Name</label>
                    <input
                      type='text'
                      name='corporateName'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.corporateName}
                      placeholder=''
                      className={
                        errors.corporateName && touched.corporateName
                          ? "input-error"
                          : null
                      }
                    />
                    {errors.corporateName && touched.corporateName && (
                      <span className='error'>{errors.corporateName}</span>
                    )}
                  </div>
                  <div className='form_input_wrapper'>
                    <label>Email Address</label>
                    <input
                      type='text'
                      name='email'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder='Email'
                      className={
                        errors.email && touched.email ? "input-error" : null
                      }
                    />
                    {errors.email && touched.email && (
                      <span className='error'>{errors.email}</span>
                    )}
                  </div>
                  <div className='form_input_wrapper password-input'>
                    <label>Password</label>
                    <input
                      type={PasswordInputType}
                      name='password'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      placeholder='Password'
                      className={
                        errors.password && touched.password
                          ? "input-error"
                          : null
                      }
                    />
                    <span className='password-toogle-icon'>{ToggleIcon}</span>
                    {errors.password && touched.password && (
                      <span className='error'>{errors.password}</span>
                    )}
                  </div>
                  <div className='form_input_wrapper password-input'>
                    <label>Password</label>
                    <input
                      type={PasswordInputType}
                      name='confirmPassword'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                      placeholder='Password'
                      className={
                        errors.confirmPassword && touched.confirmPassword
                          ? "input-error"
                          : null
                      }
                    />
                    <span className='password-toogle-icon'>{ToggleIcon}</span>
                    {errors.confirmPassword && touched.confirmPassword && (
                      <span className='error'>{errors.confirmPassword}</span>
                    )}
                  </div>{" "}
                  <div className='login-btn'>
                    <button
                      type='submit'
                      onClick={() => {
                        if (values.corporateName === "" || values.email === "" || values.password === "" || values.confirmPassword === "" || (values.confirmPassword !== values.password)) {
                          return
                        } else {
                          setToogleFormDisplay(!toogleFormDisplay);
                        }
                      }}
                    >
                      Next
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className='form_input_wrapper'>
                    <label>Contact Person’s Name</label>
                    <input
                      type='text'
                      name='contactPerson'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contactPerson}
                      placeholder=''
                      className={
                        errors.contactPerson && touched.contactPerson
                          ? "input-error"
                          : null
                      }
                    />
                    {errors.contactPerson && touched.contactPerson && (
                      <span className='error'>{errors.contactPerson}</span>
                    )}
                  </div>
                  <div className='form_input_wrapper'>
                    <label>Contact Person Email</label>
                    <input
                      type='text'
                      name='user_email'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user_email}
                      placeholder='Email'
                      className={
                        errors.user_email && touched.user_email
                          ? "input-error"
                          : null
                      }
                    />
                    {errors.user_email && touched.user_email && (
                      <span className='error'>{errors.user_email}</span>
                    )}
                  </div>
                  <div className='form_input_wrapper'>
                    <label>Phone Number</label>
                    <input
                      type='number'
                      name='phoneNumber'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber}
                      placeholder='Enter your phone number'
                      className={
                        errors.phoneNumber && touched.phoneNumber
                          ? "input-error"
                          : null
                      }
                    />
                    {errors.phoneNumber && touched.phoneNumber && (
                      <span className='error'>{errors.phoneNumber}</span>
                    )}
                  </div>
                  {/* I agree to terms & conditions */}
                  <div className='form_input_wrapper'>
                    <label>
                      <input
                        type='checkbox'
                        name='terms'
                        style={{ marginRight: "10px" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.terms}
                        placeholder=''
                        className={
                          errors.terms && touched.terms ? "input-error" : null
                        }
                      />
                      I agree to terms & conditions
                    </label>
                    {errors.terms && touched.terms && (
                      <span className='error'>{errors.terms}</span>
                    )}
                  </div>
                  <div className='login-btn'>
                    <button type='submit' disabled={!dirty}>
                      {isSubmitting ? (
                        <Spinner
                          as='span'
                          animation='border'
                          // size='sm'
                          role='status'
                          aria-hidden='true'
                          variant='light'
                        />
                      ) : (
                        "Continue"
                      )}
                    </button>
                  </div>
                </>
              )}
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RegisterFormCorporate;
